import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import StarIcon from '@material-ui/icons/Star'
import CheckIcon from '@material-ui/icons/Check'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import CardMedia from '@material-ui/core/CardMedia'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    borderRadius: '20px',
    display: 'flex',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.05)',
    flexDirection: 'row',
  },
  stats: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  stat: {
    display: 'flex',
    marginTop: theme.spacing(0.5),
    flexDirection: 'row',
    justifyContent: 'center',
  },
  avatarContent: {
    width: `${100 / 4}%`,
  },
  avatar: {
    width: '74px',
    height: '74px',
  },
  star: {
    color: '#FFD984',
    color: theme.palette.star.main,
  },
  location: {
    color: '#FFB8C6',
  },
  time: {
    color: '#9DBEFF',
  },
  insurance: {
    color: '#76DB88',
  },
  statValue: {
    color: '#979797',
    lineHeight: 1.9,
    marginLeft: theme.spacing(0.2),
  },
  content: {
    padding: 0,
    width: `${(100 / 4) * 3}%`,
    marginLeft: theme.spacing(1),
  },
  name: {
    fontSize: '1.3rem',
    fontWeight: 700,
  },
  specialty: {
    fontSize: '0.9rem',
    fontWeight: 400,
    color: '#737373',
  },
  likeYou: {
    marginTop: theme.spacing(0.5),
    fontWeight: 600,
    fontSize: '1rem',
  },
  count: {
    color: theme.palette.primary.main,
    fontWeight: 900,
    display: 'inline',
  },
  root: {
    flexDirection: 'column',
    backgroundColor: '',
    display: 'flex',
    width: '100%',
  },
}))

export default function Square({ fullName, title, avatarPath, specialties, similarPatientsCount, starRating, locationRating, waitTimeRating, insuranceRating, handleClick }) {
  const classes = useStyles()

  return (
    <Card className={classes.card} onClick={() => handleClick()}>
      <CardMedia className={classes.avatarContent}>
        <Avatar className={classes.avatar} alt={fullName} src={avatarPath} />
      </CardMedia>

      <CardContent className={classes.content}>
        <Typography className={classes.name} component='h1'>
          {fullName}, {title}
        </Typography>

        <Typography className={classes.specialty} component='h2'>
          {specialties.join(', ')}
        </Typography>

        <Typography className={classes.likeYou} component='h3'>
          <Typography className={classes.count} comnponent='span'>
            {similarPatientsCount}
          </Typography>
          patients similar to you.
        </Typography>

        <Box className={classes.stats}>
          <Box className={classes.stat}>
            <StarIcon className={classes.star} />
            <Typography className={classes.statValue}>{starRating}</Typography>
          </Box>

          <Box className={classes.stat}>
            <LocationOnIcon className={classes.location} />
            <Typography className={classes.statValue}>{locationRating}</Typography>
          </Box>

          <Box className={classes.stat}>
            <QueryBuilderIcon className={classes.time} />
            <Typography className={classes.statValue}>{waitTimeRating}</Typography>
          </Box>

          <Box className={classes.stat}>
            <CheckIcon className={classes.insurance} />
            <Typography className={classes.statValue}>{insuranceRating}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
