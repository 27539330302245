/* 🎉 3RD PARTY 🎉 */
import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createGlobalStyle } from 'styled-components'
import 'swiper/swiper.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Route } from 'react-router-dom'

/* 🎉 1ST PARTY 🎉 */
import { PROVIDER_TYPEAHEAD_ENDPOINT } from '../../lib/constants'
import { Context } from '../../lib/store'
import { actions } from '../../lib/reducer'
import Typeahead from '../../components/Typeahead'
import { GET_PROVIDER_DATA } from '../../../src/lib/constants'
import { GET_DOCTOR_PROFILE } from '../../../src/lib/constants'
import ProviderProfileCard from '../../components/ProviderProfileCard/ProviderProfileCard'
import Toaster from '../../components/Toaster/Toaster'

// TODO prune the unused style objects
// TODO refactor styles to use colors and measurements from the `theme` as such: `theme.palette` and `theme.spacing`
const useStyles = makeStyles((theme) => ({
  root: {
    background: 'linear-gradient(180deg, #181859 0%, #327BCC 71.44%)',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  stepContainer: {
    width: '100%',
    maxWidth: '1170px',
    margin: '0 auto',
    padding: 20,
    paddingTop: 10,
    boxSizing: 'border-box',
  },

  footerContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
  },

  header: {
    padding: theme.spacing(1.5),
    paddingTop: theme.spacing(3),
    background: 'transparent',
    top: '0px',
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },

  headerText: {
    width: '50%',
  },

  swiperContainer: {},

  headerTitle: {
    width: '100%',
    color: 'white',
    fontSize: 24,
    marginBottom: 20,
  },

  stepTitle: {
    color: '#CBE4FF',
    fontSize: 15,
    width: '100%',
    marginBottom: 30,
    position: 'relative',
  },

  backIcon: {
    fontSize: 20,
    position: 'absolute',
    left: 0,
    marginTop: -3,
    marginLeft: 5,
    cursor: 'pointer',
  },

  headerSubtitle: {
    color: '#CBE4FF',
    fontSize: 15,
    width: '100%',
  },

  searchByDrNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 15,
  },

  or: {
    color: '#fff',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 1,
  },

  searchByDrNameLink: {
    color: '#00B2FF',
    fontWeight: 500,
    fontSize: 18,
    textTransform: 'none',
    lineHeight: 1,
  },
  providerProfileContainer: {
    position: 'absolute',
    right: 0,
    top: '350px',
    left: 0,
  },
  searchIcon: {
    padding: 1,
    transform: 'none',
  },
  scrollRef: {
    position: 'relative',
    bottom: 300,
  },
}))

// global styles for swiper/react
const GlobalStyle = createGlobalStyle`
  #root > div {
    display: block!important;
  }
`

export default function DoctorSearch() {
  /*
    Global App State & Dispatch  
  */
  const [state, dispatch] = useContext(Context)
  const { provider, searchedProvider } = state
  let placeProvider

  if (provider) {
    placeProvider = provider.value
  }

  // Provider Typeahead UI local state
  const [isProviderTypeaheadOpen, setProviderTypeaheadOpen] = useState(false)
  const [providerOptions, setProviderOptions] = useState([])
  const [providerSearchTerm, setProviderSearchTerm] = useState('')
  const [showProfileCard, setShowProfileCard] = useState(false)
  const [selectedProvider, setSelectedProvider] = useState({})
  const [providerFetching, setProviderFetching] = useState(false)
  const [error, setError] = useState(false)
  let isProviderLoading = isProviderTypeaheadOpen
  const scrollRef = useRef(null)
  const executeScroll = () => scrollRef.current.scrollIntoView({ behavior: 'smooth' })
  const params = new URLSearchParams(window.location.search)
  const src = params.get('src')

  useEffect(() => {
    if (searchedProvider?.doctorNPI) {
      handleConnectedDoctorClick(provider.doctorNPI)
      executeScroll()
    }
  }, [])

  // Provider Typeahead data fetching
  useEffect(() => {
    if (!isProviderLoading) {
      return undefined
    } 

    axios
    .get(`https://fadapi.northwell.io/v3/suggest/typeahead?taxonomy=name&is_acp=false&q=${providerSearchTerm}`)
    .then((response) => {
      if(response.status === 200) {
        const parsed = response.data.response.results
        setProviderOptions(parsed)
      }
    })
    .catch((error) => {
      setError(true)
    })
  }, [isProviderLoading, providerSearchTerm])

  const getProviderData = (slug) => {
    setError(false)
    const fetchDr = async () => {
      const response = await fetch(`${GET_PROVIDER_DATA}?q=${slug}`)
      if (response.status === 200) {
        setShowProfileCard(true)
        const parsed = await response.json()
        //   console.log(parsed)
        setSelectedProvider(parsed)

        dispatch({
          type: actions.SET_DOC_SEARCH_PROVIDER,
          payload: parsed,
        })
        setProviderFetching(false)
      } else {
        setError(true)
      }
    }
    fetchDr()
  }

  // Set Provider data on global state
  const handleProviderChange = (event, value) => {
    if (!searchedProvider) {
      dispatch({
        type: actions.SET_DOC_SEARCH_PROVIDER,
        payload: value,
      })
    }
    // console.log(event)
    if (value) {
      getProviderData(value.slug)
      setProviderFetching(true)
    } else {
    }
  }

  const handleCloseClick = () => {
    setShowProfileCard(false)
    setProviderSearchTerm('')
    dispatch({
      type: actions.SET_DOC_SEARCH_PROVIDER,
      payload: {},
    })
  }

  const handleConnectedDoctorClick = (providerId) => {
    setShowProfileCard(true)
    setProviderFetching(true)
    const fetchData = async () => {
      const response = await fetch(`${GET_DOCTOR_PROFILE}?npi=${providerId}`)
      const parsed = await response.json()

      setSelectedProvider(parsed)

      dispatch({
        type: actions.SET_DOC_SEARCH_PROVIDER,
        payload: parsed,
      })

      executeScroll()
      setProviderFetching(false)
    }
    fetchData()
  }

  const classes = useStyles()

  return (
    <Box className={classes.root} style={showProfileCard ? { height: 'auto', position: 'relative' } : {}}>
      <GlobalStyle />

      <Box className={classes.stepContainer}>
        <div className={classes.header}>
          <Typography className={classes.stepTitle} align='center' variant='h4'>
            <Route
              render={({ history }) => (
                <FontAwesomeIcon
                  color={'#ffffff'}
                  onClick={() => {
                    if (src === 'editsearch') {
                      history.push('edit-search')
                    } else {
                      history.push('/find-care')
                    }

                    dispatch({
                      type: actions.SET_PROVIDER,
                      payload: {},
                    })
                    dispatch({
                      type: actions.SET_DOC_SEARCH_PROVIDER,
                      payload: {},
                    })
                  }}
                  className={classes.backIcon}
                  icon={['fal', 'chevron-left']}
                />
              )}
            />
            Doctor search
          </Typography>
          <Typography className={classes.headerTitle} align='center' variant='h4'>
            Already know your doctor's name?
          </Typography>
        </div>

        <Typeahead
          hasValue={!!provider}
          loading={isProviderLoading}
          loadingText={"Enter a doctor's name"}
          isRequired={true}
          disabled={showProfileCard}
          typeaheadID={'provider-typeahead'}
          placeholderText={!!placeProvider ? placeProvider : "Doctor's name"}
          options={providerOptions}
          getOptionSelected={(option, value) => {
            return option.value === value.value
          }}
          getOptionLabel={(option) => {
            return option.value
          }}
          onChange={handleProviderChange}
          onTextFieldChange={(event) => {
            setProviderSearchTerm(event.target.value)
          }}
          onOpen={() => {
            setProviderTypeaheadOpen(true)
          }}
          onClose={() => {
            setProviderTypeaheadOpen(false)
          }}
          isOpen={isProviderTypeaheadOpen}
          textFieldAutoCompleteValue={'name'}
          transformIcon={false}
          popupIcon={<FontAwesomeIcon color={'#333333'} className={classes.searchIcon} icon={['fal', 'search']} />}
        />
        <div ref={scrollRef} className={classes.scrollRef} />
      </Box>

      {showProfileCard && (
        <Box component='div' className={classes.providerProfileContainer} style={showProfileCard ? { position: 'relative', marginTop: 120, top: 0, marginBottom: 50 } : {}}>
          <ProviderProfileCard open={showProfileCard} selectedProvider={selectedProvider} handleCloseClick={handleCloseClick} onConnectedDoctorClick={(doctor) => handleConnectedDoctorClick(doctor.doctorNPI)} loading={providerFetching} doctorSearch transparentBg />
        </Box>
      )}
      {error && <Toaster showToaster={error} message='Hrmm... Something seems to have gone wrong, please try a different search.' variant='error' position='top-right' />}
    </Box>
  )
}
