/* eslint-disable react/jsx-key */
import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

/* Prototype Pages */
/*
  Note: We are not lazy-loading the Explore page on purspose.
  As per the design spec, this page should immediately load and run
  so as to create a sense of continuity in the storytelling ot how the product works.
  For this reason, we'd rather this page be pre-compiled.
*/
import Explore from '../pages/Explore'
import DoctorSearch from '../pages/DoctorSearch'

const Welcome = lazy(() => import('../pages/Welcome'))
const FindCare = lazy(() => import('../pages/FindCare'))
const EditSearch = lazy(() => import('../pages/EditSearch/EditSearch'))
const MyConstellation = lazy(() => import('../pages/MyConstellation'))

/* Other Accessible but unlinked Pages */
const DxSearchDemo = lazy(() => import('../pages/DxSearchDemo'))
const DoctorNetwork = lazy(() => import('../pages/DoctorNetwork'))
const DoctorConstellation = lazy(() => import('../pages/DoctorConstellation/DoctorConstellation'))

const routes = [
  /* Prototype Routes  */
  <Route path='/' exact component={Welcome} />,
  <Route path='/find-care' exact component={FindCare} />,
  <Route path='/edit-search' exact component={EditSearch} />,
  <Route path='/explore/:profileId?' exact component={Explore} />,
  <Route path='/doctor-search/:src?' exact component={DoctorSearch} />,
  <Route path='/my-constellation' exact component={MyConstellation} />,
  <Route path='/doctor-constellation/:src?' component={DoctorConstellation} />,
]

export default routes
