/* 🎉 3RD PARTY 🎉 */
import React, { forwardRef } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    overflow: 'visible'
  },
  drawer: {
    maxWidth: '1170px',
    margin: '0 auto',
    overflow: 'scroll',
    zIndex: 100,
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    left: 0,
    right: 0,
    bottom: 0,
    height: 'auto',
    top: theme.spacing(5),
    backgroundColor: theme.palette.white.main,
    width: '100%',
    borderTopRightRadius: 20,
    // marginBottom: 60,
    paddingBottom: 100,
    borderTopLeftRadius: 20,
    transform: 'translateY(100%)',
    transition: '150ms transform ease-in',
    '&.open': {
      transform: 'translateY(0px)',
    },
    '&.peaking': {
      transform: 'translateY(95%)',
    },
    '&.rising': {
      transform: 'translateY(90%)',
    },
    '&.spacious': {
      transform: 'translateY(132px)',
    },
  },
  drawerControl: {
    display: 'flex',
    flexDirection: 'column',
    height: '20px',
    width: '100%',
    justifyContent: 'space-between',
    padding: '25px',
    marginTop: '-10px',
    position: 'sticky',
    top: 0,
    background: 'white',
    zIndex: 999,
    cursor: 'pointer',
    transition: '250ms ease-in-out',
    '&:hover': {
      backgroundColor: '#f2f2f2',
    }
  },
  content: {
    margin: '0 auto',
    padding: 15,
  }
}))

const CLASS_STATE_MAP = {
  peaking: 'peaking',
  rising: 'rising',
  open: 'open',
  spacious: 'spacious',
  closed: '',
}

export default forwardRef(({
  children,
  state = 'closed',
  onNotchClick,
}, ref) => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <div
        ref={ref}
        className={`${classes.drawer} ${CLASS_STATE_MAP[state]}`}>
        <div className={classes.drawerControl} onClick={() => {
          if (!!onNotchClick) {
            onNotchClick()
          }
        }}>
          <img src={'/top-drawer-rectangle.svg'} alt='close me'></img>
        </div>
        <Box className={classes.content}>
          {children}
        </Box>

      </div>
    </div>

  )
})


