/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useContext, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Context } from '../../lib/store'
import StarIcon from '@material-ui/icons/Star'
import Paper from '@material-ui/core/Paper'
import Slide from '@material-ui/core/Slide'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import SectionCard from './SectionCard/SectionCard'
import IconCard from './IconCard/IconCard'
import { actions } from '../../lib/reducer'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'
import ConnectionCard from './ConnectionCard/ConnectionCard'
import LoadingSkeleton from './LoadingSkeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ShareModal from './ShareModal/ShareModal'
import { createMapsLink } from '../../lib/utils'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    position: 'absolute',
    width: '100%',

    '& .MuiAvatar-img': {
      objectFit: 'contain',
      position: 'relative',
      top: '8px',
      height: 'auto',
    },

    '& progress': {
      backgroundColor: 'white',
      borderRadius: '50px',
      color: '#181858',
      width: '224px',
      height: '10px',
    },
    '& progress::-webkit-progress-value': {
      backgroundColor: '#181858',
      borderRadius: '50px',
    },
    '& progress::-webkit-progress-bar': {
      backgroundColor: 'white',
    },
    '& progress::-moz-progress-value': {
      backgroundColor: '#181858',
    },
    '& progress::-moz-progress-bar': {
      backgroundColor: '#181858',
      borderRadius: '50px',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: '-90px',
    right: '30px',
    color: 'white',
    fontSize: '30px',
    cursor: 'pointer',
  },
  wrapper: {
    maxWidth: '1170px',
    margin: '0 auto',
    width: '100%',
  },
  paper: {
    zIndex: 1,
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '0px',
  },
  avatar: {
    width: '150px',
    height: '150px',
    margin: '0 auto',
    bottom: '75px',
  },
  contentWrapper: {
    position: 'relative',
    top: '-75px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '16px',
    fontSize: '16px',
  },
  name: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  specialty: {
    fontSize: '16px',
    fontWeight: 300,
    maxWidth: '350px',
    textAlign: 'center',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '20px',
    margin: '15px 0 25px 0',
  },
  secondaryButton: {
    width: '335px',
    height: '50px',
    border: '1px solid black',
    borderRadius: '25px',
  },
  maaBtnWrapper: {
    marginBottom: '25px',
  },
  maaBtn: {
    width: '335px',
    height: '50px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '50px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
    fontSize: '19px',
    lineHeight: '13px',
    textTransform: 'none',

    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    },
  },
  profileCardShareBtn: {
    display: 'none',
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  similarPatients: {
    fontWeight: 800,
  },
  totalPatients: {
    fontWeight: 300,
  },
  connectedDoctors: {
    fontWeight: 300,
  },
  link: {
    cursor: 'pointer',
    color: '#34A8FE',
    fontWeight: 600,
  },
  showMoreLink: {
    height: '60px',
    cursor: 'pointer',
    color: '#34A8FE',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textField: {
    width: '95%',
    height: '45px',
    border: '1px solid #D0D0D0',
    borderRadius: '6px',
    paddingLeft: '5px',
  },
  textWrapper: {
    maxWidth: '250px',
  },
  locationWrapper: {
    maxHeight: '480px',
    overflow: 'hidden',
    transition: '500ms ease-in-out',
  },
  mapWrapper: {
    paddingBottom: '25px',
  },
  map: {
    position: 'relative',
    right: '40px',
    bottom: '10px',
  },
  locationDetails: {
    padding: '20px 0px 25px 0px',
    maxWidth: '240px',
  },
  practiceName: {
    fontWeight: 400,
  },
  practiceAddress: {
    fontWeight: 300,
  },
  phoneNumberWrapper: {
    paddingTop: '25px',
  },
  phoneNumber: {
    color: '#00B2FF',
    textDecoration: 'none',
    fontWeight: 300,
  },
  conditionsContainer: {
    overflow: 'hidden',
    maxHeight: '170px',
    marginBottom: '10px',
    transition: '500ms ease-in-out',
  },
  slideOpen: {
    maxHeight: '2500px',
  },
  conditionName: {
    fontSize: '16px',
    letterSpacing: '0.5px',
    fontWeight: 300,
    textTransform: 'capitalize',
    width: '250px',
  },
  progressBar: {
    marginTop: '5px',
    marginBottom: '15px',
  },
  dataWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '15px',
  },
  countsByAgeWrapper: {
    paddingRight: '15px',
  },
  countsByAge: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 300,
    padding: '10px 0',
  },
  patientCount: {
    fontWeight: 500,
  },
  divider: {
    border: '1px solid #E3E3E3',
    width: '100%',

    '&:last-of-type': {
      display: 'none',
    },
  },
  insurancePlan: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px',
    border: '1px solid #D0D0D0',
    borderRadius: '6px',
  },
  insuranceName: {
    width: '200px',
    fontWeight: 300,
  },
  checkIcon: {
    color: '#76DB88',
    fontSize: '25px',
    marginRight: '8px',
  },
  dataBar: {
    height: '20px',
    width: '95%',
    borderRadius: '15px',
  },
  legend: {
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingTop: '20px',
  },
  key: {
    display: 'flex',
  },
  tel: {
    color: '#fff',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      paddingLeft: 10,
    },
  },
}))

const ProviderProfileCard = ({ open, handleCloseClick, onConnectedDoctorClick, closeProfile, loading, selectedProvider, doctorSearch, transparentBg }) => {
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()
  const [state, dispatch] = useContext(Context)
  const { insurancePlan } = state
  const [showMoreConditions, setShowMoreConditions] = useState(false)
  const [showMoreLocations, setShowMoreLocations] = useState(false)
  let maleCount = (selectedProvider?.countsByGender && selectedProvider?.countsByGender[1]?.patientCount) || 0
  let femaleCount = (selectedProvider?.countsByGender && selectedProvider?.countsByGender[0]?.patientCount) || 0
  let totalGenderCount = maleCount + femaleCount
  const [showModal, setShowModal] = useState(false)
  const scrollRef = useRef(null)
  const executeScroll = () => scrollRef.current.scrollIntoView({ behavior: 'smooth' })

  const handleShowMoreDoctorsClick = () => {
    if (location.pathname === '/doctor-constellation') {
      closeProfile()
    }

    let nextPage = doctorSearch ? '/doctor-constellation?src=search' : '/doctor-constellation?src=explore'

    history.push(nextPage)
    dispatch({
      type: actions.SET_PROVIDER,
      payload: selectedProvider,
    })
  }

  const shareBtn = () => {
    setShowModal(true)
  }

  const hideModal = () => {
    setShowModal(false)
  }

  const handleConnectedDoctorClick = (connectedDoc) => {
    onConnectedDoctorClick(connectedDoc)
  }

  const plainPhone = selectedProvider?.locations?.[0].phoneNumber.replace(/[- )(]/g, '')

  return (
    <div className={classes.root} style={transparentBg ? { background: 'transparent', position: 'relative' } : {}}>
      <div className={classes.wrapper}>
        <Slide direction='up' in={open} timeout={150} mountOnEnter unmountOnExit>
          <Paper elevation={4} className={classes.paper}>
            <div>
              <CloseIcon className={classes.closeIcon} onClick={handleCloseClick} />
              {!loading && <Avatar className={classes.avatar} alt={selectedProvider?.fullName} src={selectedProvider?.avatarPath} />}
              <div className={classes.contentWrapper}>
                {loading ? (
                  <LoadingSkeleton />
                ) : (
                  <>
                    <Typography className={classes.name} component='h1'>
                      {selectedProvider?.fullName}
                    </Typography>
                    <Typography className={classes.specialty} component='h3' variant='subtitle1'>
                      {selectedProvider?.specialties}
                    </Typography>

                    <div className={classes.buttonWrapper}>
                      <Button className={classes.secondaryButton + ' ' + classes.profileCardShareBtn} onClick={shareBtn}>
                        Share
                      </Button>
                    </div>

                    {selectedProvider?.totalPatients && 
                      <SectionCard id='patientVisits' showTooltip={true}>
                        <div className={classes.textWrapper}>
                          <p className={classes.totalPatients}>{`${selectedProvider?.totalPatients} ${selectedProvider?.totalPatients > 1 ? 'patients visit' : 'patient visits'} Dr. ${selectedProvider?.lastName}`}</p>
                          {selectedProvider?.similarPatients && (
                            <p className={classes.similarPatients}>
                              {selectedProvider?.similarPatients} {selectedProvider?.similarPatients > 1 ? 'are similar to you.' : 'is similar to you.'}
                            </p>
                          )}
                        </div>
                      </SectionCard>
                    }

                    {selectedProvider?.connectedDoctors?.length > 0 && (
                      <SectionCard id='connections' showTooltip={true}>
                        <div className={classes.textWrapper}>
                          <p className={classes.connectedDoctors}>
                            {selectedProvider?.connectedDoctors && `${selectedProvider?.connectedDoctors.length} ${selectedProvider?.connectedDoctors.length > 1 ? 'doctors share patients' : 'doctor shares patients'} with Dr. ${selectedProvider?.lastName}.`}
                            <span className={classes.link} onClick={() => executeScroll()}>
                              &nbsp; View them here
                            </span>
                          </p>
                        </div>
                      </SectionCard>
                    )}
                    {insurancePlan && (
                      <SectionCard cardTitle='Insurance' id='insurance' showTooltip={true}>
                        <div className={classes.insurancePlan}>
                          <p className={classes.insuranceName}>{insurancePlan.name}</p>
                          <FontAwesomeIcon className={classes.checkIcon} icon={['fal', 'check-circle']} />
                        </div>
                      </SectionCard>
                    )}

                    {selectedProvider?.is_online_scheduling ? (
                      <div className={classes.maaBtnWrapper}>
                        <Button
                          className={classes.maaBtn}
                          onClick={(e) => {
                            e.preventDefault()
                            window.open(`http://northwell.edu/make-an-appointment/${selectedProvider?.doctorEchoID}`, '_blank')
                          }}
                        >
                          Make an appointment
                        </Button>
                      </div>
                    ) : (
                      <div className={classes.maaBtnWrapper}>
                        <Button className={classes.maaBtn}>
                          <a href={`tel:+1${plainPhone}`} className={classes.tel} rel='nofollow' title={`Click to call ${selectedProvider?.locations?.[0].phoneNumber}`}>
                            <FontAwesomeIcon className={classes.phoneIcon} style={{ background: '#fff', width: 33, height: 33, padding: 7, borderRadius: '50%', color: '#33a8fe' }} icon={['fas', 'phone']} />
                            <span>{selectedProvider?.locations?.[0].phoneNumber}</span>
                          </a>
                        </Button>
                      </div>
                    )}

                    <SectionCard cardTitle='Locations' id='locations' showTooltip={true} showMore={showMoreLocations}>
                      <div className={showMoreLocations ? `${classes.locationWrapper} ${classes.slideOpen}` : classes.locationWrapper}>
                        {selectedProvider?.locations &&
                          selectedProvider?.locations.map((location, index) => (
                            <div key={index} className={classes.mapWrapper}>
                              <div className={classes.map}>
                                <a href={createMapsLink(location)} target='_blank' rel='noreferrer'>
                                  <img src={`https://maps.locationiq.com/v3/staticmap?key=pk.3abfd02e6d1f7e708f19cbc588f3a63e&zoom=15&size=375x267&format=png&maptype=roadmap&markers=icon:large-blue-cutout%7C${location.latitude},${location.longitude}`} alt='map of location' />
                                </a>
                              </div>
                              <div className={classes.locationDetails}>
                                <p className={classes.practiceName}> {location.practiceName} </p>
                                <p className={classes.practiceAddress}>
                                  {`${location.streetAddress}${location.suite ? ', ' + location.suite : ''}`}
                                  <br />
                                  {`${location.city}, ${location.state} ${location.zipCode}`}
                                </p>
                                {location.phoneNumber && (
                                  <div className={classes.phoneNumberWrapper}>
                                    <a className={classes.phoneNumber} href={`tel:+1${location.phoneNumber.replace(/[^a-zA-Z0-9]/g, '')}`}>
                                      <p>{location.phoneNumber}</p>
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                      {selectedProvider?.locations && selectedProvider?.locations.length > 1 && (
                        <>
                          <span onClick={() => setShowMoreLocations(!showMoreLocations)} className={classes.showMoreLink}>
                            {showMoreLocations ? 'Show less' : 'Show more'}
                          </span>
                        </>
                      )}
                    </SectionCard>
                    {selectedProvider?.starRating && (
                      <IconCard
                        icon={<StarIcon color='inherit' />}
                        iconClass='StarsIcon'
                        title={`${Math.round(selectedProvider?.starRating * 10) / 10} Stars Overall`}
                        subheader={
                          <>
                            {selectedProvider?.surveyCount} ratings,{' '}
                            <a href={`${selectedProvider?.URL}#section-ratings-quote`} target='_blank' rel='noreferrer'>
                              {selectedProvider?.surveyCommentCount} comments
                            </a>
                          </>
                        }
                      />
                    )}

                    {selectedProvider?.topConditions?.length > 0 && (
                      <SectionCard cardTitle='Conditions Treated' id='conditions' showTooltip={true}>
                        <div className={showMoreConditions ? `${classes.conditionsContainer} ${classes.slideOpen}` : classes.conditionsContainer}>
                          {selectedProvider?.topConditions &&
                            selectedProvider?.topConditions.map((condition, index) => (
                              <div key={index}>
                                <p className={classes.conditionName}>{condition.conditionName}</p>
                                <div className={classes.dataWrapper}>
                                  <div>
                                    <progress className={classes.progressBar} max={selectedProvider?.topConditions[0].patientCount} value={condition.patientCount} />
                                  </div>
                                  <div>{condition.patientCount}</div>
                                </div>
                              </div>
                            ))}
                        </div>
                        {selectedProvider?.topConditions?.length > 2 && (
                          <span onClick={() => setShowMoreConditions(!showMoreConditions)} className={classes.showMoreLink}>
                            {showMoreConditions ? 'Show less' : 'Show more'}
                          </span>
                        )}
                      </SectionCard>
                    )}

                    {selectedProvider?.countsByAge && selectedProvider?.countsByAge?.length > 0 &&
                      <SectionCard cardTitle='Age of Patients' id='age' showTooltip={true}>
                        {selectedProvider?.countsByAge &&
                          selectedProvider?.countsByAge.map((count) => (
                            <>
                              <div className={classes.countsByAgeWrapper}>
                                <div className={classes.countsByAge}>
                                  <div>{count.ageGroup}</div>
                                  <div className={classes.patientCount}>{count.patientCount}</div>
                                </div>
                              </div>
                              <hr className={classes.divider} />
                            </>
                          ))}
                      </SectionCard>
                    }

                    {selectedProvider?.countsByGender && selectedProvider?.countsByGender.length > 0 &&
                      <SectionCard cardTitle='Patient Gender' id='gender' showTooltip={true}>
                        <div
                          className={classes.dataBar}
                          style={{
                            background: `linear-gradient(to right, #181858 ${(maleCount / totalGenderCount) * 100}%, #327BCC 0%)`,
                          }}
                        />
                        <div className={classes.legend}>
                          <div className={classes.key}>
                            <p>Male</p>
                            <div style={{ margin: '4px', height: '12px', width: '12px', backgroundColor: '#181858', borderRadius: '3px' }} />
                          </div>
                          <div className={classes.key}>
                            <p>Female</p>
                            <div style={{ margin: '4px', height: '12px', width: '12px', backgroundColor: '#327BCC', borderRadius: '3px' }} />
                          </div>
                        </div>
                      </SectionCard>
                    }
                    <div ref={scrollRef}>
                      {selectedProvider?.connectedDoctors?.length > 0 && (
                        <SectionCard cardTitle="Doctor's Connections" id='doctorConnections' showTooltip={true}>
                          <>
                            {selectedProvider?.connectedDoctors && selectedProvider?.connectedDoctors.slice(0, 3).map((doctor) => <ConnectionCard connectedDoctor={doctor} providerLastName={selectedProvider?.lastName} onClick={() => handleConnectedDoctorClick(doctor)} />)}
                            {selectedProvider?.connectedDoctors?.length > 2 && (
                              <span className={classes.showMoreLink} onClick={() => handleShowMoreDoctorsClick()}>
                                Show more
                              </span>
                            )}
                          </>
                        </SectionCard>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </Paper>
        </Slide>
        <ShareModal show={showModal} onClick={hideModal} name={selectedProvider?.fullName} image={selectedProvider?.avatarPath} url={selectedProvider?.URL} description={`Specialist in ${selectedProvider?.specialties}`} />
      </div>
    </div>
  )
}

export default ProviderProfileCard
