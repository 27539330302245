export class Link {
  constructor(source, target, weight) {
    this.source = source
    this.target = target
    this.weight = weight
  }
}

export class Node {
  constructor(id, type) {
    this.id = id
    this.type = type
  }
}

export function createConstellationData(response) {
  const patientNodeID = 5942
  // const rawVisits = response['raw_visits']
  const updatedNodes = [...response.graphData.nodes]
  const userNode = new Node(5942, 'User')
  const doctorNodes = updatedNodes.filter(({ type }) => type === 'Doctor')
  const patientNodes = []
  const patientDoctorLinks = []
  // Object.keys(rawVisits).forEach((doctorID) => {
  //   return Object.entries(rawVisits[doctorID]).forEach(([patientId, visitCount]) => {
  //     // only push a new patient note to the array if it doesn't already exist.
  //     // and then create a link from that patient to the doctor
  //     const existingPatientNode = patientNodes.find(({ id }) => id === patientId)
  //     if (!existingPatientNode) {
  //       patientNodes.push(new Node(patientId, 'Patient'))
  //     }
  //     patientDoctorLinks.push(new Link(parseInt(doctorID, 10), patientId, visitCount))
  //   })
  // })

  const userPatientLinks = patientNodes.map((node) => {
    return new Link(patientNodeID, node.id, 5)
  })
  const nodes = [userNode, ...doctorNodes, ...patientNodes]
  // window.nodes = nodes
  const links = [...patientDoctorLinks, ...userPatientLinks]
  const output = Object.assign({}, response)
  output.graphData = { nodes, links }

  return output
}

export const createMapsLink = (address) => {
  const baseMap = 'https://www.google.com/maps/place/'
  const encodedStreet = encodeURIComponent(address.streetAddress)
  const encodedCity = encodeURIComponent(address.city)

  const encodedMap = `${baseMap}${encodedStreet}+${encodedCity}+${address.state}+${address.zipCode}`

  return encodedMap
}

export const chunk = (arr, len) => {
  var chunks = [],
    i = 0,
    n = arr.length

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)))
  }

  return chunks
}

// write cookie script to create cookie for Doug
// get cookie value for _ga and populate _cia_ga
// eslint-disable-next-line no-undef
export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

// eslint-disable-next-line no-undef
export const getGaClientId = () => ga.getAll()[0]?.get('clientId');

