/* 🎉 3RD PARTY 🎉 */
import React from 'react'
import App from 'base-shell/lib'
import MUIConfig from 'material-ui-shell/lib'
import merge from 'base-shell/lib/utils/config'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { createGlobalStyle } from 'styled-components'
import * as LoadScript from 'react-load-script'
import './App.scss'

/* 🎉 1ST PARTY 🎉 */
import Store from './lib/store'
import _config from './config'

library.add(fab, far, fal, fas)

const config = merge(MUIConfig, _config)

// Bit of a hack —— hide Material UI mobile menu
const GlobalStyle = createGlobalStyle`
.body{
  background: linear-gradient(180deg, #181859 0%, #327BCC 71.44%)
}
  .at-icon-wrapper {
    height: 80px !important;
    width: 80px !important;
    margin-left: -4px;
    margin-top: -4px;
    svg {
      height: 80px !important;
      width: 80px !important;
    }
    .at-label {
      padding: 0 !important;
    }
    &:nth-child(2) {
      height: 240px;
    }
    &.at-svc-sms {
      border-radius: 40px !important;
    }
  }
  .at-share-btn-elements {
    margin: 160px auto 0;
    width: 240px;
    pointer-events: auto;
    display: flex;
    justify-content: center;
    .at-share-btn.at-svc-email {
      margin-right: 60px;
      pointer-events: auto;
    }
    .at-share-btn at-svc-sms {
      position: relative;
      top: -120px;
      pointer-events: auto;
    }
  }
`

const ConstellationApp = () => {
  const handleScriptLoad = () => {
    window.addthis.init()
  }
  return (
    <Store>
      <LoadScript url='//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-536cecf77eecd315' onLoad={handleScriptLoad} />
      <GlobalStyle />
      <App config={config} />
    </Store>
  )
}

export default ConstellationApp
