/* 🎉 3RD PARTY 🎉 */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* 🎉 1ST PARTY 🎉 */

const useStyles = makeStyles((theme) => ({
  customPlaceholder: {
    '& input::placeholder': {
      color: '#333333',
      opacity: '1 !important',
    },
  },
  required: {
    color: theme.palette.required.main,
    position: 'absolute',
    right: 50,
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: 12,
  },
  container: {
    backgroundColor: '#fff',
    position: 'relative',
    borderRadius: 10,
    marginBottom: 15,
  },
  autoSelectNoTransform: {
    '& .MuiAutocomplete-endAdornment': {
      paddingRight: 5,
      '& .MuiAutocomplete-popupIndicator': {
        transform: 'none',
      },
    },
  },
  autoSelect: {
    '& .MuiAutocomplete-endAdornment': {
      paddingRight: 5,
    },
  },
}))

// TODO Solve for the case where the user clears the input. Currently, the last selected value lingers. The correct behavior is to remove this value from the UI.
export default function Typeahead({
  hasValue,
  inputValue,
  defaultValue,
  loading,
  loadingText,
  isRequired,
  typeaheadID,
  transformIcon,
  popupIcon,
  placeholderText,
  options,
  textFieldAutoCompleteValue,
  getOptionSelected,
  getOptionLabel,
  onChange,
  onOpen,
  onClose,
  isOpen,
  endAdornment,
  onTextFieldChange,
  disabled,
}) {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      {!hasValue && isRequired && <Typography className={classes.required}>Required</Typography>}
      <Autocomplete
        id={typeaheadID}
        inputValue={inputValue}
        defaultValue={defaultValue}
        open={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        getOptionSelected={getOptionSelected}
        onChange={onChange}
        getOptionLabel={getOptionLabel}
        options={options}
        disabled={disabled}
        loading={loading}
        loadingText={loadingText}
        className={!transformIcon ? classes.autoSelectNoTransform : classes.autoSelect}
        popupIcon={popupIcon}
        closeIcon={<FontAwesomeIcon color={'#333333'} icon={['fal', 'times']} />}
        clearOnEscape={true}
        renderInput={(params) => {
          return <TextField {...params} onChange={onTextFieldChange} autoComplete={textFieldAutoCompleteValue} required={isRequired} placeholder={placeholderText} variant={'outlined'} classes={hasValue ? { root: classes.customPlaceholder } : null} />
        }}
      />
    </Box>
  )
}

Typeahead.propTypes = {
  onTextFieldChange: PropTypes.func,
}

Typeahead.defaultProps = {
  onTextFieldChange: () => {},
}
