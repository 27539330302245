import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import StarIcon from '@material-ui/icons/Star'
import CheckIcon from '@material-ui/icons/Check'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import CardMedia from '@material-ui/core/CardMedia'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiAvatar-img': {
      objectFit: 'contain',
      position: 'relative',
      top: '15px',
      height: 'auto',
    },
  },
  card: {
    // width: '430px',
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: '20px',
    display: 'flex',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.05)',
    flexDirection: 'row',
    '&:hover': {
      cursor: 'pointer',
    },
    // '@media (max-width: 767px)': {
    //   padding: theme.spacing(1)
    // },
  },
  stats: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  stat: {
    display: 'flex',
    marginTop: theme.spacing(0.5),
    flexDirection: 'row',
    justifyContent: 'center',
  },
  avatarContent: {
    width: `${100 / 4}%`,
  },
  avatar: {
    width: '74px',
    height: '74px',
  },
  star: {
    color: theme.palette.star.main,
  },
  location: {
    color: '#FFB8C6',
  },
  time: {
    color: '#9DBEFF',
  },
  insurance: {
    color: '#76DB88',
  },
  statValue: {
    color: '#979797',
    lineHeight: 1.9,
    marginLeft: theme.spacing(0.2),
    [theme.breakpoints.down('xs')]: {
      fontSize: '.8rem',
    },
  },
  content: {
    padding: '0!important',
    width: `${(100 / 4) * 3}%`,
    marginLeft: theme.spacing(1),
  },
  name: {
    fontSize: '1.3rem',
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1rem',
    },
  },
  specialty: {
    fontSize: '0.9rem',
    fontWeight: 400,
    color: '#737373',
  },
  likeYou: {
    marginTop: theme.spacing(0.5),
    fontWeight: 600,
    fontSize: '1rem',
    display: 'inline',
    [theme.breakpoints.down('xs')]: {
      fontSize: '.8rem',
    },
  },
  count: {
    color: theme.palette.primary.main,
    fontWeight: 900,
    display: 'inline',
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.down('xs')]: {
      fontSize: '.8rem',
    },
  },
}))

export default function Square({ fullName, titles, avatarPath, specialties, similarPatientsCount, sharedPatientsCount, starRating, locationRating, insuranceRating, handleClick, lastName }) {
  const classes = useStyles()

  return (
    <Card className={classes.card} onClick={() => handleClick()}>
      <CardMedia className={classes.avatarContent}>
        <Avatar className={classes.avatar} alt={fullName} src={avatarPath} />
      </CardMedia>

      <CardContent className={classes.content}>
        <Typography className={classes.name} component='h1'>
          {fullName}
        </Typography>

        <Typography className={classes.specialty} component='h2'>
          {specialties}
        </Typography>

        {similarPatientsCount && (
          <Box>
            <Typography className={classes.count} component='h3'>
              {similarPatientsCount}
            </Typography>
            <Typography className={classes.likeYou} component='h3'>
              patient{similarPatientsCount > 1 ? 's' : ''} similar to you.
            </Typography>
          </Box>
        )}

        {sharedPatientsCount && (
          <Box>
            <Typography className={classes.count} component='h3'>
              {sharedPatientsCount}
            </Typography>
            <Typography className={classes.likeYou} component='h3'>
              patient{sharedPatientsCount > 1 ? 's' : ''} shared with Dr. {lastName}.
            </Typography>
          </Box>
        )}

        <Box className={classes.stats}>
          <Box className={classes.stat}>
            <StarIcon className={classes.star} />
            <Typography className={classes.statValue}>{!!starRating ? starRating?.toFixed(1) : 'NA'}</Typography>
          </Box>
          {locationRating && (
            <Box className={classes.stat}>
              <LocationOnIcon className={classes.location} />
              <Typography className={classes.statValue}>{`${locationRating?.toFixed(1)} mi`}</Typography>
            </Box>
          )}
          {insuranceRating && (
            <Box className={classes.stat}>
              <CheckIcon className={classes.insurance} />
              <Typography className={classes.statValue}>{insuranceRating ? 'In-Network' : 'Out-of-Network'}</Typography>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}
