import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  skeleton: {
    display: 'inline-block',
    position: 'relative',
    overflow: 'hidden',
    maxWidth: '335px',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
    borderRadius: '20px',
    marginBottom: '20px',
    backgroundColor: '#DDDBDD',

    '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    left: '-150px',
    top: 0,
    height: '100%',
    width: '150px',
    background: 'linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%)',
    animation: '$load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite',
    },
  },
  '@keyframes load': {
    'from': {
        left: '-150px'
    },
    'to': {
        left: '100%'
    }
  },
  avatar: {
    height: '150px',
    width: '150px',
    borderRadius: '75px',
    marginBottom: '20px'
  },
  title: {
    height: '35px',
    width: '200px',
  },
  subTitle: {
    height: '20px',
    width: '250px'
  },
  buttonWrapper: {
    display: 'flex',
    columnGap: '20px',
  },
  button: {
    width: '157px',
    height: '50px',
    borderRadius: '25px',
  },
  largeButton: {
    width: '100%',
    height: '50px',
    borderRadius: '25px',
  },
  block: {
    height: '100px',
    width: '100%',
  },
  largeBlock: {
    height: '450px',
    width: '100%',
  }
}))


const LoadingSkeleton = () => {
  const classes = useStyles()

  return (
    <>
      <div className={`${classes.skeleton} ${classes.avatar}`} />
      <div className={`${classes.skeleton} ${classes.title}`} />
      <div className={`${classes.skeleton} ${classes.subTitle}`} />
      <div className={classes.buttonWrapper}>
        <div className={`${classes.skeleton} ${classes.button}`} />
        <div className={`${classes.skeleton} ${classes.button}`} />
      </div>
      <div className={`${classes.skeleton} ${classes.block}`} />
      <div className={`${classes.skeleton} ${classes.block}`} />
      <div className={`${classes.skeleton} ${classes.largeButton}`} />
      <div className={`${classes.skeleton} ${classes.largeBlock}`} />
      <div className={`${classes.skeleton} ${classes.block}`} />
      <div className={`${classes.skeleton} ${classes.block}`} />
      <div className={`${classes.skeleton} ${classes.block}`} />
      <div className={`${classes.skeleton} ${classes.block}`} />
    </>
  )
}

export default LoadingSkeleton