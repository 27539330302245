/* 🎉 3RD PARTY 🎉 */
import React, { useState, useContext, useRef, useEffect, useLayoutEffect } from 'react'
import { uid } from 'uid'
import { Route, Link } from 'react-router-dom'
import * as THREE from 'three'
import ForceGraph3D from '3d-force-graph'
import sample from 'lodash.sample'
import samplesize from 'lodash.samplesize'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { createGlobalStyle } from 'styled-components'
import SearchIcon from '@material-ui/icons/Search'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputBase from '@material-ui/core/InputBase'
import Pagination from '@material-ui/lab/Pagination'
import Toaster from '../../components/Toaster/Toaster'
import { getCookie, getGaClientId } from '../../lib/utils'

/* 🎉 1ST PARTY 🎉 */
import { selectProviders } from '../../lib/state'
import { selectCounts } from '../../lib/state'
import { Context } from '../../lib/store'
import { actions } from '../../lib/reducer'
import { Rectangle } from '../../components/ProviderCard'
import Drawer from '../../components/Drawer'
import ProviderProfileCard from '../../components/ProviderProfileCard/ProviderProfileCard'
import { GET_DOCTOR_PROFILE } from '../../../src/lib/constants'
import { chunk } from '../../lib/utils'
import { truncate } from 'lodash'

const GlobalStyle = createGlobalStyle`
  #root {
    height: 100vh;
  }
  #root > div {
    min-height: 100%;
  }
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  /* Firefox < 16 */
  @-moz-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }
  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }
  /* Internet Explorer */
  @-ms-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }
  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    background: 'linear-gradient(180deg, #181859 0%, #327BCC 71.44%)',
    padding: theme.spacing(2.5),
    width: '100%',

    '& .MuiAvatar-img': {
      objectFit: 'contain',
      position: 'relative',
      top: '9px',
      height: 'auto',
    },
  },

  viewHeader: {
    display: 'flex',
    maxWidth: '1170px',
    margin: '0 auto',
    width: '100%',
    flexDirection: 'column',
    marginTop: 20,
    zIndex: 30,
    '@media (max-width: 767px)': {
      position: 'fixed',
      left: 0,
      padding: '0px 15px',
    },
  },

  viewDescriptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
  },

  viewDescription: {
    color: '#fff',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '125%',
  },

  viewDescriptionEditLink: {
    fontSize: 14,
    color: '#00B2FF',
    lineHeight: '125%',
    fontWeight: 'normal',
  },
  link: {
    cursor: 'pointer',
    color: '#34A8FE',
    fontWeight: 600,
    textDecoration: 'none',
  },
  viewTitle: {
    fontSize: 16,
    color: '#CBE4FF',
    fontWeight: 'bold',
    lineHeight: '112%',
    marginBottom: 20,
  },

  appBarRoot: {
    marginLeft: '-20px',
    marginRight: '-20px', // full width bottom app bar
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 9999,
    boxShadow: '0px -4px 15px rgba(0, 0, 0, 0.15)',
    '& .MuiTab-wrapper': {
      textTransform: 'none',
    },
    '& .MuiTab-textColorSecondary.Mui-selected': {
      color: '#055AFF',
    },
  },

  providerProfileContainer: {
    top: '13%',
    left: '0px',
    right: '0px',
    zIndex: '999',
    position: 'absolute',
    width: '100%',
    maxWidth: '1170px',
    margin: '0 auto',
  },

  scene: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 0,
    background: 'linear-gradient(180deg, #181859 0%, #327BCC 71.44%)',
  },

  storyLine: {
    fontSize: 24,
    animation: 'fadein 0.6s ease-in',
    transition: '600ms ease-in opacity',
    color: '#fff',
    fontWeight: 'bold',
    zIndex: 10,
    position: 'fixed',
    lineHeight: '1.25',
    top: theme.spacing(6),
    left: 0,
    width: '100%',
    '&.dimmed': {
      opacity: 0,
    },
  },

  breadCrumb: {
    padding: theme.spacing(1.5),
    animation: 'fadein .6s ease-in',
    borderRadius: '10px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 100,
  },

  searchParamsSummary: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  searchIcon: {
    minWidth: '40px',
  },

  drawerHeading: {
    marginTop: 45,
    fontWeight: 800,
    fontSize: 40,
    color: '#191C5D',
  },

  drawerContentWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    transition: '350ms opacity ease-out',
    opacity: 0,
    '&.visible': {
      opacity: 1,
    },
  },

  sortForm: {
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
  },

  providersList: {
    marginBottom: 20,
    width: '100%',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const RESULTS_CHUNK = 30

// motion timing settings
const SECOND = 1000
const USER_NODE_POPULATE_TIMEOUT = 1 * SECOND
const FIRST_STORY_LINE_TIMEOUT = 1.5 * SECOND
const SECOND_STORY_LINE_TIMEOUT = 2 * SECOND
const PATIENT_NODES_POPULATE_TIMEOUT = 3 * SECOND
const DOCTOR_NODES_POPULATE_TIMEOUT = 2 * SECOND
const SEARCH_RESULTS_PEAKING_TIMEOUT = 2 * SECOND
const SEARCH_RESULTS_SHOWING_TIMEOUT = 4 * SECOND
const PATIENT_NODES_POPULATE_INTERVAL = 30
const DOCTOR_NODES_POPULATE_INTERVAL = 50

let graph = null

// reference to intervals
let patientNodesIntervalRef = null
let doctorNodesIntervalRef = null

// reference to timeouts
let userNodeTimeoutRef = null
let patientNodesTimeoutRef = null
let doctorNodesTimeoutRef = null
let firstStoryLineTimeoutRef = null
let secondtStoryLineTimeoutRef = null
let searchResutPeakingTimoutRef = null
let searchResutVisibleTimoutRef = null

// motion value configs
let MAX_PATIENT_COUNT = 30
let MAX_DOCTOR_COUNT = 15
const USER_NODE_ID = uid()
const TYPES = { USER: 'user', PATIENT: 'patient', DOCTOR: 'doctor' }
const constellation = { nodes: [], links: [] }
function createNode({ type, id }) {
  return { type, id }
}
function createLink({ source, target, weight }) {
  return { source, target, weight }
}

const DropDownInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: 'white',
    border: 'none',
    fontSize: 16,
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
    padding: '15px',
    '&:focus': {},
  },
}))(InputBase)

export default function LoadResults() {
  const classes = useStyles()

  // global state and dispatch
  const [state, dispatch] = useContext(Context)
  const { provider, userGraphData, providerSearchResults, insurancePlan, userLocation, condition, specialty, userAge, userGender } = state

  // local state and dispatch
  const [error, setError] = useState(false)
  const [isFirstStoryLineVisible, setFirstStoryLineVisible] = useState(false)
  const [isSecondStoryLineVisible, setSecondStoryLineVisible] = useState(false)
  const [isResultsPeaking, setResultsPeaking] = useState(false)
  const [isResultsVisible, setResultsVisible] = useState(false)
  const [isAnimationComplete, setAnimationComplete] = useState(false)
  const [isMotionSequenceComplete, setMotionSequenceComplete] = useState(false)
  const [isPatientAnimationComplete, setPatientAnimationComplete] = useState(false)
  // const [tabsValue, setTabsValue] = useState(0)
  const [drawerState, setDrawerState] = useState('closed')
  const [providersList, setProvidersList] = useState(selectProviders(providerSearchResults))
  const [providerCounts, setProviderCounts] = useState(selectCounts(providerSearchResults))
  const [selectedProvider, setSelectedProvider] = useState()
  const [sort, setSort] = useState('Best Match')
  const [showProfileCard, setShowProfileCard] = useState(false)
  const [loadingProfileData, setLoadingProfileData] = useState(false)
  const [resultsPageNum, setResultsPageNum] = useState(1)
  const scrollRef = useRef(null)
  const executeScroll = () => scrollRef.current.scrollIntoView({ behavior: 'smooth' })
  const ref = useRef()

  const initializeGraph = (data, el) => {
    if (userGraphData) {
      data = userGraphData
      setDrawerState('rising')
      setAnimationComplete(true)
      setPatientAnimationComplete(true)
      setMotionSequenceComplete(true)
    }
    graph = ForceGraph3D({
      rendererConfig: {
        alpha: true,
        antialias: true,
      },
      controlType: 'orbit',
    })(el)
      .backgroundColor('rgba(0, 0, 0, 0)')
      .height(window.innerHeight)
      .enablePointerInteraction(true)
      .showNavInfo(false)
      .graphData(data)
      .enableNodeDrag(false)
      .zoomToFit(300, 50)
      .nodeThreeObject(function (node) {
        let material = null
        let geometry = null
        if (node.type === TYPES.USER) {
          material = new THREE.MeshPhongMaterial({ color: 0xffffff, wireframe: false })
          geometry = new THREE.SphereGeometry(5, 32, 32)
          return new THREE.Mesh(geometry, material)
        } else if (node.type === TYPES.DOCTOR) {
          material = new THREE.MeshBasicMaterial({ color: 0x50caff, wireframe: false })
          geometry = new THREE.SphereGeometry(9, 32, 32)
          const mesh = new THREE.Mesh(geometry, material)
          const group = new THREE.Group()
          group.add(mesh)
          return group
        } else if (node.type === TYPES.PATIENT) {
          material = new THREE.MeshBasicMaterial({ color: 0x3ba4d1, wireframe: false })
          geometry = new THREE.SphereGeometry(5, 32, 32)
          return new THREE.Mesh(geometry, material)
        }
      })
      .linkMaterial(function (link) {
        // if it is a link between User and Patients
        if ((typeof link.source === 'number' && link.source === USER_NODE_ID) || (typeof link.source === 'object' && link.source.id === USER_NODE_ID)) {
          return new THREE.MeshBasicMaterial({ color: 0x327bcc, wireframe: false, visible: false })
        } else {
          return new THREE.MeshBasicMaterial({ color: 0x9bcee4, wireframe: false })
        }
      })
      .onNodeHover((node) => (el.style.cursor = node ? 'pointer' : null))

    window.graph = graph
  }

  // pausing the animation helps with performance
  // see: https://github.com/vasturiano/3d-force-graph#render-control
  useEffect(() => {
    if (isResultsVisible) {
      if (!!graph) {
        graph.pauseAnimation()
      }
    } else {
      if (!!graph) {
        graph.resumeAnimation()
      }
    }
  }, [isResultsVisible])

  // enable and disable visualization interactions
  useEffect(() => {
    if (isAnimationComplete) {
      if (!!graph) {
        graph.enableNavigationControls(true)
      }
    } else {
      if (!!graph) {
        graph.enableNavigationControls(false)
      }
    }
  }, [isAnimationComplete])

  // choreograph the drawer state based on the motion sequence in this view
  useEffect(() => {
    if (isResultsPeaking) {
      setDrawerState('peaking')
    } else if (isResultsVisible) {
      setDrawerState('spacious')
    } else if (isMotionSequenceComplete) {
      setDrawerState('rising')
    } else {
      setDrawerState('closed')
    }

    if (showProfileCard) setDrawerState('closed')
    // setDrawerState(drawerState)
  }, [isResultsPeaking, isResultsVisible, isMotionSequenceComplete, showProfileCard, drawerState])

  const populatePatientNodes = (interval) => {
    patientNodesIntervalRef = window.setInterval(() => {
      if (MAX_PATIENT_COUNT > 0) {
        const { nodes, links } = graph.graphData()
        const id = uid()
        graph.graphData({
          nodes: [...nodes, createNode({ type: TYPES.PATIENT, id })],
          links: [...links, createLink({ source: USER_NODE_ID, target: id, weight: 10 })],
        })

        MAX_PATIENT_COUNT--
      } else if (MAX_PATIENT_COUNT === 0) {
        // patient node animation complete
        window.clearInterval(patientNodesIntervalRef)
        window.clearTimeout(patientNodesTimeoutRef)
        // fade out the first story line here
        setPatientAnimationComplete(true)
        secondtStoryLineTimeoutRef = window.setTimeout(() => {
          setSecondStoryLineVisible(true)
          doctorNodesTimeoutRef = window.setTimeout(() => {
            populateDoctorNodes(DOCTOR_NODES_POPULATE_INTERVAL)
            window.clearTimeout(doctorNodesTimeoutRef)
          }, DOCTOR_NODES_POPULATE_TIMEOUT)
          window.clearTimeout(secondtStoryLineTimeoutRef)
        }, SECOND_STORY_LINE_TIMEOUT)
      }
    }, interval)
  }

  const populateDoctorNodes = (interval) => {
    doctorNodesIntervalRef = window.setInterval(() => {
      if (MAX_DOCTOR_COUNT > 0) {
        const { nodes, links } = graph.graphData()
        // create either two or three links from Doctor node to patient
        const linkCount = sample([2, 3])
        const allPatientIDs = nodes.filter(({ type }) => type === TYPES.PATIENT).map(({ id }) => id)
        const patientCandidates = samplesize(allPatientIDs, linkCount)

        const docID = uid()

        graph.graphData({
          nodes: [...nodes, createNode({ type: TYPES.DOCTOR, id: docID })],
          links: [...links, ...patientCandidates.map((patientID) => createLink({ source: patientID, target: docID, weight: 10 }))],
        })

        MAX_DOCTOR_COUNT--
      } else if (MAX_DOCTOR_COUNT === 0) {
        searchResutPeakingTimoutRef = window.setTimeout(() => {
          setResultsPeaking(true)
          setAnimationComplete(true)
          window.clearTimeout(searchResutPeakingTimoutRef)
          dispatch({
            type: actions.SET_USER_GRAPH,
            payload: graph.graphData(),
          })
        }, SEARCH_RESULTS_PEAKING_TIMEOUT)

        searchResutVisibleTimoutRef = window.setTimeout(() => {
          setResultsPeaking(false)
          setResultsVisible(true)
          setMotionSequenceComplete(true)
          window.clearTimeout(searchResutVisibleTimoutRef)
        }, SEARCH_RESULTS_SHOWING_TIMEOUT)

        window.clearInterval(doctorNodesIntervalRef)
        window.clearTimeout(doctorNodesTimeoutRef)
      }
    }, interval)
  }

  const sceneEl = useRef(null)

  useEffect(() => {
    // reset graph counter everytime page rerenders
    MAX_PATIENT_COUNT = 30
    MAX_DOCTOR_COUNT = 15

    if (provider.doctorNPI) {
      handleProviderCardClick(provider.doctorNPI)
    }

    // make the graph
    initializeGraph(constellation, sceneEl.current)

    if (!userGraphData) {
      // add user node
      userNodeTimeoutRef = window.setTimeout(() => {
        const { nodes, links } = graph.graphData()

        // first, add the user node
        graph.graphData({
          nodes: [...nodes, createNode({ type: TYPES.USER, id: USER_NODE_ID })],
          links,
        })
      }, USER_NODE_POPULATE_TIMEOUT)

      // fade in the first story line
      firstStoryLineTimeoutRef = window.setTimeout(() => {
        setFirstStoryLineVisible(true)
        window.clearTimeout(firstStoryLineTimeoutRef)
      }, FIRST_STORY_LINE_TIMEOUT)

      // add patient nodes
      patientNodesTimeoutRef = window.setTimeout(() => {
        populatePatientNodes(PATIENT_NODES_POPULATE_INTERVAL)

        window.clearTimeout(userNodeTimeoutRef)
      }, PATIENT_NODES_POPULATE_TIMEOUT)

      return () => {
        graph = null
        window.clearTimeout(userNodeTimeoutRef)
        window.clearTimeout(patientNodesTimeoutRef)
        window.clearTimeout(doctorNodesTimeoutRef)
        window.clearTimeout(searchResutPeakingTimoutRef)
        window.clearTimeout(searchResutVisibleTimoutRef)
        window.clearInterval(patientNodesIntervalRef)
        window.clearInterval(doctorNodesIntervalRef)
      }
    }
    console.log(userGraphData)
    console.log(selectedProvider)
  }, [])

  // const handleTabsChange = (event, newValue, history) => {
  //   if (newValue === 0) {
  //     history.push('/explore')
  //   } else if (newValue === 1) {
  //     history.push('/my-constellation')
  //   }
  // }
  const handleSortChange = (event) => {
    setSort(event.target.value)
    setResultsPageNum(1)
    executeScroll()
  }

  // handle sort change
  useEffect(() => {
    let sorted = null
    // eslint-disable-next-line default-case
    switch (sort) {
      case 'Best Match':
        sorted = [...providersList].sort((a, b) => {
          return b.combined_score - a.combined_score
        })
        setProvidersList(sorted)
        break
      case 'Similar Patients':
        sorted = [...providersList].sort((a, b) => {
          return b.similarPatients - a.similarPatients || a.locationRating - b.locationRating
        })
        setProvidersList(sorted)
        break
      case 'Location':
        sorted = [...providersList].sort((a, b) => {
          return a.locationRating - b.locationRating || b.similarPatients - a.similarPatients
        })
        setProvidersList(sorted)
        break
    }
  }, [sort])

  const handleProviderCardClick = (providerId) => {
    setError(false)
    ref.current.scrollIntoView({
      behavior: 'smooth',
    })
    setShowProfileCard(true)
    setLoadingProfileData(true)
    const fetchData = async () => {
      const response = await fetch(`${GET_DOCTOR_PROFILE}?npi=${providerId}&_ga=${getCookie('_ga')}&client=${getGaClientId()}`)
      if (response.status === 200) {
        const parsed = await response.json()

        const doctor = providersList.filter((provider) => {
          if (provider.fullName === parsed.fullName) {
            return provider.similarPatients
          } else return null
        })
        parsed['similarPatients'] = doctor[0]?.similarPatients ?? null

        setSelectedProvider(parsed)

        dispatch({
          type: actions.SET_PROVIDER,
          payload: parsed,
        })

        setLoadingProfileData(false)
      } else {
        setError(true)
        setShowProfileCard(false)

      }
    }
    fetchData()
  }

  const handleCloseClick = () => {
    setShowProfileCard(false)

    // reset provider state if provider card is closed.
    dispatch({
      type: actions.SET_PROVIDER,
      payload: {},
    })
  }

  const handlePaginationChange = (e, value) => {
    setResultsPageNum(value)
    executeScroll()
  }

  const generateSearchCriteriaText = () => {
    let searchCriteria = [`${insurancePlan?.name ?? ''}, ${userLocation?.value ?? ''}, ${condition?.term_name ?? ''}, ${specialty?.specialty_name ?? ''}, ${userAge?.name ?? ''}, ${userGender?.name ?? ''}`]

    return searchCriteria
      .join(' ')
      .replace(/(\s*,\s*)+/g, ', ')
      .replace(/(^[,\s]+)|([,\s]+$)/g, '')
  }

  window.addEventListener('resize', function () {
    graph.width(window.innerWidth)
    graph.height(window.innerHeight)
    graph.refresh()
  })

  return (
    <div ref={ref} className={classes.root}>
      <GlobalStyle />

      {isMotionSequenceComplete && !showProfileCard && (
        <Route
          render={({ history }) => (
            <Box className={classes.viewHeader}>
              <Typography variant='h1' align={'center'} className={classes.viewTitle}>
                Search results
              </Typography>
              <Box className={classes.viewDescriptionContainer}>
                <Typography variant='h2' className={classes.viewDescription}>
                  Your search criteria
                </Typography>
                <Typography className={classes.viewDescriptionEditLink}>
                  <Link to='/edit-search' className={classes.link}>
                    Edit
                  </Link>
                </Typography>
              </Box>
              <Paper
                onClick={() => {
                  history.push('/edit-search')
                }}
                className={classes.breadCrumb}
                elevation={1}
              >
                <Typography className={classes.searchParamsSummary} variant='h6'>
                  {generateSearchCriteriaText()}
                </Typography>
                <SearchIcon className={classes.searchIcon} />
              </Paper>
            </Box>
          )}
        />
      )}

      {!isMotionSequenceComplete && isFirstStoryLineVisible && (
        <Typography align={'center'} className={`${classes.storyLine} ${isPatientAnimationComplete ? 'dimmed' : ''}`}>
          There {providerCounts.patients > 1 ? `are ${providerCounts.patients} patients` : `is ${providerCounts.patients} patient`}
          <br />
          similar to you in our system.
        </Typography>
      )}

      {!isMotionSequenceComplete && isSecondStoryLineVisible && (
        <Typography align={'center'} className={classes.storyLine}>
          They see {providerCounts.doctors} {providerCounts.doctors > 1 ? `doctors` : `doctor`}
          <br />
          in our universe.
        </Typography>
      )}
      {showProfileCard && (
        <Typography variant='h1' align={'center'} className={classes.viewTitle}>
          {`Doctor's Profile`}
        </Typography>
      )}
      <div
        className={classes.scene}
        style={{
          zIndex: 0,
        }}
        ref={sceneEl}
      ></div>

      <Drawer
        state={drawerState}
        isTabBarVisible={false}
        onNotchClick={() => {
          if (isAnimationComplete) {
            setResultsVisible(!isResultsVisible)
          }
        }}
      >
        <div ref={scrollRef} className={`${classes.drawerContentWrapper} ${isResultsVisible ? 'visible' : ''}`}>
          <Typography className={classes.drawerHeading} align={'left'} variant={'h1'}>
            {providerCounts.doctors} {providerCounts.doctors > 1 ? `doctors are` : `doctor is`} <br />
            seeing {providerCounts.patients > 1 ? `${providerCounts.patients} patients` : `${providerCounts.patients} patient`} <br />
            similar to you.
          </Typography>

          <FormControl className={classes.sortForm}>
            <Select labelId='sort-results-label' id='sort-resuts' value={sort} onChange={handleSortChange} input={<DropDownInput />}>
              <MenuItem value={'Best Match'}>Best match</MenuItem>
              <MenuItem value={'Similar Patients'}>Similar patients</MenuItem>
              <MenuItem value={'Location'}>Location</MenuItem>
            </Select>
          </FormControl>

          <div className={classes.providersList}>
            {chunk(providersList, RESULTS_CHUNK)[resultsPageNum - 1].map((provider, index) => {
              return (
                <Route
                  key={index}
                  render={({ history }) => (
                    <Rectangle
                      key={index}
                      handleClick={() => handleProviderCardClick(provider.id)}
                      fullName={provider.fullName}
                      avatarPath={provider.avatarPath}
                      specialties={provider.specialties}
                      similarPatientsCount={provider.similarPatients}
                      starRating={provider.starRating}
                      locationRating={provider.locationRating}
                      insuranceRating={true}
                    />
                  )}
                />
              )
            })}
          </div>
        </div>
        <div style={{ marginBottom: '50px' }}>
          <Pagination count={chunk(providersList, RESULTS_CHUNK).length} page={resultsPageNum} onChange={handlePaginationChange} className={classes.pagination} />
        </div>
      </Drawer>
      <Box component='div' className={classes.providerProfileContainer}>
        <ProviderProfileCard open={showProfileCard} selectedProvider={selectedProvider || {}} handleCloseClick={handleCloseClick} onConnectedDoctorClick={(doctor) => handleProviderCardClick(doctor.doctorNPI)} loading={loadingProfileData} />
      </Box>
      {error &&
        <Toaster 
          showToaster={error} 
          message="Hrmm... Something seems to have gone wrong, please try again"
          variant="error"
          position="top-right"
        />
      }
      {/* {isMotionSequenceComplete && (
        <Paper square className={classes.appBarRoot}>
          <Route
            render={({ history }) => (
              <Tabs
                value={tabsValue}
                onChange={(event, index) => {
                  handleTabsChange(event, index, history)
                }}
                variant='fullWidth'
                indicatorColor='secondary'
                textColor='secondary'
                aria-label='App Navigation Bar'
              >
                <Tab disableRipple icon={<RocketIcon color={tabsValue === 0 ? '#055AFF' : '#333'} />} label='Explore' />
                <Tab disableRipple icon={<ConstellationIcon color={tabsValue === 1 ? '#055AFF' : '#333'} />} label='My Constellation' />
              </Tabs>
            )}
          />
        </Paper>
      )} */}
    </div>
  )
}
