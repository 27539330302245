import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SvgIcon, Typography } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '335px',
    height: '96px',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
    borderRadius: '20px',
    marginBottom: '20px',
  },
  cardHeader: {
    padding: '16px 20px',
    position: 'relative',
    top: '12px',
  },
  avatar: {
    color: 'pink',
  },
  GroupIcon: {
    backgroundColor: '#00B2FF',
  },
  StarsIcon: {
    backgroundColor: theme.palette.highlight.main,
    color: theme.palette.highlight.main,
    '& .MuiSvgIcon-root': {
      fill: 'white'
    },
  },
  subColor: {
    '& a': {
      textDecoration: 'none',
      color: '#34A8FE',      
    }
  }
}))

const IconCard = ({ icon, iconClass, title, subheader }) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label='people' className={classes[iconClass]}>
            {icon}
          </Avatar>
        }
        titleTypographyProps={{ variant: 'h5' }}
        title={title}
        subheaderTypographyProps={{ variant: 'subtitle1' }}
        subheader={<Typography className={classes.subColor}>{subheader}</Typography>}
        classes={{
          root: classes.cardHeader,
        }}
      />
    </Card>
  )
}

export default IconCard