import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import StarIcon from '@material-ui/icons/Star'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  
  root: {
    '& .MuiAvatar-img': {
      objectFit: 'contain',
      position: 'relative',
      top: '8px',
      height: 'auto'
    },
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 'auto',
    paddingTop: '20px',
    cursor: 'pointer',
  },
  contentContainer: {

  },
  avatar: {
    width: '75px',
    height: '75px',
  },
  divider: {
    border: '1px solid #E3E3E3',
    width: '100%',
  },
  totalPatients: {
    fontWeight: 300
  },
  sharedPatients: {
    fontWeight: 700
  },
  specialties: {
    fontSize: '12px',
    color: '#737373',
    paddingTop: '10px'
  },
  star: {
    color: theme.palette.star.main,
  },
  ratingText: {
    position: 'relative',
    color: '#979797',
    bottom: '5px',
    fontSize: '13px',
  }
}))

const ConnectionCard = ({connectedDoctor, providerLastName, onClick}) => {
  const classes = useStyles()

  const handleClick = (provider) => {
    onClick(provider)
  }

  return (
    <>
      <div className={classes.cardWrapper} onClick={() => handleClick(connectedDoctor)}>
        <div className={classes.contentContainer}>
          <p className={classes.totalPatients}>{`${connectedDoctor.totalPatients} patients visit Dr. ${connectedDoctor.LastName}`}</p>
          <p className={classes.sharedPatients}>{`${connectedDoctor.sharedPatients} are shared with Dr. ${providerLastName}`}</p>
          <p className={classes.specialties}>{connectedDoctor.specialties}</p>
        </div>
        <Avatar className={classes.avatar} alt={connectedDoctor.fullName} src={connectedDoctor.avatarPath} />
      </div>
      <div>
        <span>
        <StarIcon className={classes.star} />
          <span className={classes.ratingText}>{connectedDoctor.star_rating?.toFixed(1) ?? 'N/A'}</span>
        </span>
        </div>
      <hr className={classes.divider} />
    </>
  )
}

export default ConnectionCard