/* 3rd party */
import React, { Component, createContext, useReducer } from 'react'

/* 1st party */
import reducer from './reducer'
import { initialState } from './state'
import { STATUS } from './constants'  

const Store = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
      <Context.Provider value={[state, dispatch]}>
        {children}
      </Context.Provider>
  )
}

export const Context = createContext(initialState)
export default Store