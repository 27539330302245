import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Tooltip from '@material-ui/core/Tooltip'
import Fade from '@material-ui/core/Fade';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TOOLTIP_MAP } from '../../../lib/constants';
import { withStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '335px',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
    borderRadius: '20px',
    marginBottom: '20px',
  },
  helpIcon: {
    color: '#00B2FF',
    fontSize: '19px',
  },
  contentWrapper: {
    paddingTop: '30px',
    paddingLeft: '25px',
  },
  tooltipWrapper: {
    position: 'relative',
    float: 'right',
    padding: '25px 25px 0px 0px',
    top: '5px',
    right: '5px',
  },
  title: {
    fontSize: '19px',
    fontWeight: 700,
    padding: '30px 0px 0px 25px'
  },
  withoutTitle: {
    position: 'relative',
    float: 'right',
    padding: '25px 25px 0px 0px',
  },
  withTitle: {
    paddingTop: '15px',
    paddingLeft: '25px',
  },
  tooltip: {
    backgroundColor: 'white',
    boxShadow: 'none',
    height: '20px',
    width: '20px',
    minHeight: '20px',
    '&:hover' : {
      backgroundColor:'white',
    }
  },
}))

const DefaultTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white',
    color: '#1b2265',
    fontSize: '12px',
    fontWeight: '600',
    border: "2px solid #E6E8ED"
  },
  arrow: {
    fontSize: 20,
    color: "#4A4A4A",
    "&::before": {
      backgroundColor: "white",
      border: "2px solid #E6E8ED"
    }
  }
})(Tooltip);

const SectionCard = ({ id, cardTitle, children, showTooltip }) => {
  const classes = useStyles()

  const tooltipText =
    TOOLTIP_MAP.map(tip => {
      if (tip.id === id) {
        return tip.text
      } else return null
    })

  return (
    <Card id={id} className={classes.root}>
       {showTooltip &&
        <div className={cardTitle ? classes.tooltipWrapper : classes.withoutTitle}>
          {showTooltip ?
            <DefaultTooltip 
              title={tooltipText}
              TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}
              className={classes.tooltipContent}
              placement="top"
              arrow
              enterTouchDelay={0}
            >
              <Fab className={classes.tooltip}>
                <FontAwesomeIcon className={classes.helpIcon } icon={['fal', 'question-circle']} />
              </Fab>
            </DefaultTooltip>
          : 
            ''}
        </div>
      }
      {cardTitle && 
        <div className={classes.title}>
          {cardTitle}
        </div>
      }
      <CardContent className={cardTitle ? classes.withTitle : classes.contentWrapper}>{children}</CardContent>
    </Card>
  )
}

export default SectionCard
