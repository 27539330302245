export const ageRanges = [
  { name: 'Under 18', slug: 'Under 18' },
  { name: '19 to 24', slug: '18-24' },
  { name: '25 to 34', slug: '25-34' },
  { name: '35 to 44', slug: '35-44' },
  { name: '45 to 54', slug: '45-54' },
  { name: '55 to 64', slug: '55-64' },
  { name: '65 to 74', slug: '65-74' },
  { name: '75 and above', slug: '75+' },
]

export const genders = [
  { name: 'Male', slug: 'M' },
  { name: 'Female', slug: 'F' },
]

export const graphData404 = {}

export const providersList = [
  {
    fullName: 'Shuchie Jaggi',
    titles: ['DO'],
    avatarPath: '/s-j.png',
    specialties: ['Endocrinology / Metab / Diabetes', 'Internal Medicine'],
    locations: ['865 Northern Boulevard, Suite 203 Great Neck, NY'],
    sharedPatientsCount: 130,
  },
  {
    fullName: 'Agustin Busta',
    titles: ['MD'],
    avatarPath: '/a-b.png',
    specialties: ['Endocrinology / Metab / Diabetes', 'Internal Medicine'],
    locations: ['110 East 59th Street, Suite 8B New York, NY'],
    sharedPatientsCount: 84,
  },
  {
    fullName: 'Natalie Elissa Cusano',
    titles: ['MD', 'MS'],
    avatarPath: '/n-e-c.png',
    specialties: ['Endocrinology / Metab / Diabetes', 'Internal Medicine'],
    locations: ['110 East 59th Street, Suite 8B New York, NY'],
    sharedPatientsCount: 183,
  },
  {
    fullName: 'Shuchie Jaggi',
    titles: ['DO'],
    avatarPath: '/s-j.png',
    specialties: ['Endocrinology / Metab / Diabetes', 'Internal Medicine'],
    locations: ['865 Northern Boulevard, Suite 203 Great Neck, NY'],
    sharedPatientsCount: 130,
  },
  {
    fullName: 'Agustin Busta',
    titles: ['MD'],
    avatarPath: '/a-b.png',
    specialties: ['Endocrinology / Metab / Diabetes', 'Internal Medicine'],
    locations: ['110 East 59th Street, Suite 8B New York, NY'],
    sharedPatientsCount: 84,
  },
  {
    fullName: 'Natalie Elissa Cusano',
    titles: ['MD', 'MS'],
    avatarPath: '/n-e-c.png',
    specialties: ['Endocrinology / Metab / Diabetes', 'Internal Medicine'],
    locations: ['110 East 59th Street, Suite 8B New York, NY'],
    sharedPatientsCount: 183,
  },
]

// stub data for development
export const apiResponse = {
  counts: {
    doctors: 18,
    patients: 24,
  },
  graphData: {
    links: [
      {
        source: 1184634503,
        target: 5942,
        weight: 0.33101673561832806,
      },
      {
        source: 1184063174,
        target: 5942,
        weight: 9.865069087414717,
      },
      {
        source: 1427070655,
        target: 5942,
        weight: 2.961467926659376,
      },
      {
        source: 1235593211,
        target: 5942,
        weight: 2.966671071936322,
      },
      {
        source: 1477539260,
        target: 5942,
        weight: 8.981051432736402,
      },
      {
        source: 1003046285,
        target: 5942,
        weight: 1.2757688625150685,
      },
      {
        source: 1174703193,
        target: 5942,
        weight: 1.2771160379410196,
      },
      {
        source: 1033114202,
        target: 5942,
        weight: 8.981051432736402,
      },
      {
        source: 1386640696,
        target: 5942,
        weight: 8.981051432736402,
      },
      {
        source: 1104080175,
        target: 5942,
        weight: 2.966671071936322,
      },
      {
        source: 1982604740,
        target: 5942,
        weight: 2.0145205067418943,
      },
      {
        source: 1619955960,
        target: 5942,
        weight: 3.2922787434289833,
      },
      {
        source: 1316180292,
        target: 5942,
        weight: 0.44596750640635296,
      },
      {
        source: 1518973593,
        target: 5942,
        weight: 8.981051432736402,
      },
      {
        source: 1871564005,
        target: 5942,
        weight: 9.865069087414717,
      },
      {
        source: 1114961687,
        target: 5942,
        weight: 7.393758908596071,
      },
      {
        source: 1912968181,
        target: 5942,
        weight: 1.6395032338971256,
      },
      {
        source: 1407814080,
        target: 5942,
        weight: 2.357502496787503,
      },
    ],
    nodes: [
      {
        addressStreet: '5 Cuba Hill Road',
        addressSuite: null,
        avatarPath: 'https://fadapi.northwell.io/images/providers/dr-michael-b-grosso-md-11314712.jpg',
        city: 'Greenlawn',
        fullName: 'Michael B. Grosso, MD',
        id: 1184634503,
        locationRating: 0.33101673561832806,
        phoneNumber: '(631) 628-5000',
        practiceName: 'Mount Sinai North Shore Medical Group',
        similarPatients: 1,
        specialties: ['Pediatrics'],
        starRating: null,
        state: 'NY',
        totalAppointments: 1,
        type: 'Doctor',
        zipCode: '11740',
      },
      {
        addressStreet: '3001 Express Drive North',
        addressSuite: 'Suite 100',
        avatarPath: 'https://fadapi.northwell.io/images/providers/dr-allison-mangini-do-11371961.jpg',
        city: 'Islandia',
        fullName: 'Allison Mangini, DO',
        id: 1184063174,
        locationRating: 9.865069087414717,
        phoneNumber: '(631) 434-1770',
        practiceName: "Cohen Children's Northwell Health Physician Partners General Pediatrics at Islandia",
        similarPatients: 1,
        specialties: ['Pediatrics'],
        starRating: 4.87171,
        state: 'NY',
        totalAppointments: 6,
        type: 'Doctor',
        zipCode: '11749',
      },
      {
        addressStreet: '270 Park Avenue',
        addressSuite: null,
        avatarPath: 'https://fadapi.northwell.io/images/providers/dr-david-ira-bergman-md-11308311.jpg',
        city: 'Huntington',
        fullName: 'David Ira Bergman, MD',
        id: 1427070655,
        locationRating: 2.961467926659376,
        phoneNumber: '(631) 351-2219',
        practiceName: null,
        similarPatients: 4,
        specialties: ['Maternal / Fetal Medicine', 'Obstetrics and Gynecology'],
        starRating: 4.90583,
        state: 'NY',
        totalAppointments: 11,
        type: 'Doctor',
        zipCode: '11743',
      },
      {
        addressStreet: '270 Park Avenue',
        addressSuite: null,
        avatarPath: 'https://fadapi.northwell.io/images/providers/dr-mir-raza-md-11381668.jpg',
        city: 'Huntington',
        fullName: 'Mir Raza, MD',
        id: 1235593211,
        locationRating: 2.966671071936322,
        phoneNumber: '(631) 351-2254',
        practiceName: 'HH - Dept of Emergency Medicine',
        similarPatients: 1,
        specialties: ['Emergency Medicine'],
        starRating: null,
        state: 'NY',
        totalAppointments: 2,
        type: 'Doctor',
        zipCode: '11743',
      },
      {
        addressStreet: '285 Middle Country Road',
        addressSuite: 'Suite 100',
        avatarPath: 'https://fadapi.northwell.io/images/providers/dr-gabriel-alejandro-sanroman-md-11349189.jpg',
        city: 'Smithtown',
        fullName: 'Gabriel Alejandro SanRoman, MD',
        id: 1477539260,
        locationRating: 8.981051432736402,
        phoneNumber: '(631) 650-2229',
        practiceName: 'Northwell Health Fertility at Smithtown',
        similarPatients: 1,
        specialties: ['Reproductive Endo / Infertility', 'Obstetrics and Gynecology'],
        starRating: 4.88335,
        state: 'NY',
        totalAppointments: 1,
        type: 'Doctor',
        zipCode: '11787',
      },
      {
        addressStreet: '284 Pulaski Road',
        addressSuite: null,
        avatarPath: 'https://fadapi.northwell.io/images/providers/dr-shabana-perveen-md-11369734.jpg',
        city: 'Greenlawn',
        fullName: 'Shabana Perveen, MD',
        id: 1003046285,
        locationRating: 1.2757688625150685,
        phoneNumber: '(631) 470-1460',
        practiceName: null,
        similarPatients: 1,
        specialties: ['Surgery', 'Surgical Critical Care'],
        starRating: 4.78571,
        state: 'NY',
        totalAppointments: 4,
        type: 'Doctor',
        zipCode: '11740',
      },
      {
        addressStreet: '284 Pulaski Road',
        addressSuite: 'Suite 1',
        avatarPath: null,
        city: 'Greenlawn',
        fullName: 'Lisa Rolon, MD',
        id: 1174703193,
        locationRating: 1.2771160379410196,
        phoneNumber: '(631) 425-5250',
        practiceName: 'Northwell Health Dolan Family Health Center',
        similarPatients: 2,
        specialties: ['Obstetrics and Gynecology'],
        starRating: null,
        state: 'NY',
        totalAppointments: 3,
        type: 'Doctor',
        zipCode: '11740',
      },
      {
        addressStreet: '222 Middle Country Road',
        addressSuite: 'Suite 106',
        avatarPath: 'https://fadapi.northwell.io/images/providers/dr-marion-beth-rose-md-11363454.jpg',
        city: 'Smithtown',
        fullName: 'Marion Beth Rose, MD',
        id: 1033114202,
        locationRating: 8.981051432736402,
        phoneNumber: '(631) 439-5437',
        practiceName: "Cohen Children's Northwell Health Physician Partners Pediatric Specialists at Smithtown",
        similarPatients: 5,
        specialties: ['Pediatric Cardiology'],
        starRating: 4.90017,
        state: 'NY',
        totalAppointments: 5,
        type: 'Doctor',
        zipCode: '11787',
      },
      {
        addressStreet: '222 Middle Country Road',
        addressSuite: 'Suite 106',
        avatarPath: 'https://fadapi.northwell.io/images/providers/dr-rohit-talwar-md-11313980.jpg',
        city: 'Smithtown',
        fullName: 'Rohit Talwar, MD',
        id: 1386640696,
        locationRating: 8.981051432736402,
        phoneNumber: '(631) 439-5437',
        practiceName: "Cohen Children's Northwell Health Physician Partners Pediatric Specialists at Smithtown",
        similarPatients: 2,
        specialties: ['Pediatric Cardiology', 'Pediatrics'],
        starRating: 4.52515,
        state: 'NY',
        totalAppointments: 2,
        type: 'Doctor',
        zipCode: '11787',
      },
      {
        addressStreet: '270 Park Avenue',
        addressSuite: null,
        avatarPath: 'https://fadapi.northwell.io/images/providers/dr-ryan-patrick-oneill-do-11371879.jpg',
        city: 'Huntington',
        fullName: "Ryan Patrick O'Neill, DO",
        id: 1104080175,
        locationRating: 2.966671071936322,
        phoneNumber: '(631) 351-2427',
        practiceName: 'HH - Dept of Emergency Medicine',
        similarPatients: 1,
        specialties: ['Emergency Medicine'],
        starRating: null,
        state: 'NY',
        totalAppointments: 1,
        type: 'Doctor',
        zipCode: '11743',
      },
      {
        addressStreet: '241 East Main Street',
        addressSuite: 'Suite 1A',
        avatarPath: 'https://fadapi.northwell.io/images/providers/dr-rose-sherley-antilus-md-11370991.jpg',
        city: 'Huntington',
        fullName: 'Rose Sherley Antilus, MD',
        id: 1982604740,
        locationRating: 2.0145205067418943,
        phoneNumber: null,
        practiceName: null,
        similarPatients: 1,
        specialties: ['Obstetrics and Gynecology'],
        starRating: 4.58342,
        state: 'NY',
        totalAppointments: 15,
        type: 'Doctor',
        zipCode: '11743',
      },
      {
        addressStreet: '777 Larkfield Road',
        addressSuite: null,
        avatarPath: 'https://fadapi.northwell.io/images/providers/dr-luis-a-bracero-md-11363294.jpg',
        city: 'Commack',
        fullName: 'Luis A. Bracero, MD',
        id: 1619955960,
        locationRating: 3.2922787434289833,
        phoneNumber: '(631) 396-7000',
        practiceName: 'Northwell Health Physician Partners Obstetrics and Gynecology at Commack',
        similarPatients: 7,
        specialties: ['Obstetrics and Gynecology', 'Maternal / Fetal Medicine'],
        starRating: 4.35807,
        state: 'NY',
        totalAppointments: 12,
        type: 'Doctor',
        zipCode: '11725',
      },
      {
        addressStreet: '5 Cuba Hill Road',
        addressSuite: 'Floor 5',
        avatarPath: 'https://fadapi.northwell.io/images/providers/dr-karen-stein-barasch-do-11362045.jpg',
        city: 'Greenlawn',
        fullName: 'Karen Stein Barasch, DO',
        id: 1316180292,
        locationRating: 0.44596750640635296,
        phoneNumber: '(631) 628-5000',
        practiceName: null,
        similarPatients: 1,
        specialties: ['Obstetrics and Gynecology'],
        starRating: null,
        state: 'NY',
        totalAppointments: 2,
        type: 'Doctor',
        zipCode: '11740',
      },
      {
        addressStreet: '222 Middle Country Road',
        addressSuite: 'Suite 114',
        avatarPath: 'https://fadapi.northwell.io/images/providers/dr-felicia-tirico-callan-md-11354527.jpg',
        city: 'Smithtown',
        fullName: 'Felicia Tirico Callan, MD',
        id: 1518973593,
        locationRating: 8.981051432736402,
        phoneNumber: '(631) 775-3290',
        practiceName: 'Northwell Health Physician Partners Obstetrics & Gynecology at Smithtown',
        similarPatients: 1,
        specialties: ['Obstetrics and Gynecology'],
        starRating: 4.84797,
        state: 'NY',
        totalAppointments: 1,
        type: 'Doctor',
        zipCode: '11787',
      },
      {
        addressStreet: '3001 Express Drive North',
        addressSuite: 'Suite 112',
        avatarPath: 'https://fadapi.northwell.io/images/providers/dr-robin-laurell-bliss-md-11359713.jpg',
        city: 'Islandia',
        fullName: 'Robin Laurell Bliss, MD',
        id: 1871564005,
        locationRating: 9.865069087414717,
        phoneNumber: '(631) 242-6747',
        practiceName: 'Northwell Health Physician Partners Gynecology at Islandia',
        similarPatients: 1,
        specialties: ['Obstetrics and Gynecology'],
        starRating: 4.93442,
        state: 'NY',
        totalAppointments: 1,
        type: 'Doctor',
        zipCode: '11749',
      },
      {
        addressStreet: '43 Crossways Park Drive West',
        addressSuite: null,
        avatarPath: 'https://fadapi.northwell.io/images/providers/dr-russell-jonathan-schiff-md-11308102.jpg',
        city: 'Woodbury',
        fullName: 'Russell Jonathan Schiff, MD',
        id: 1114961687,
        locationRating: 7.393758908596071,
        phoneNumber: '(516) 992-5205',
        practiceName: 'Northwell Health Physician Partners Adult & Pediatric Cardiology at Woodbury',
        similarPatients: 1,
        specialties: ['Pediatric Cardiology', 'Pediatrics'],
        starRating: 4.90017,
        state: 'NY',
        totalAppointments: 1,
        type: 'Doctor',
        zipCode: '11797',
      },
      {
        addressStreet: '752 Park Avenue',
        addressSuite: null,
        avatarPath: 'https://fadapi.northwell.io/images/providers/dr-theodore-lyle-goldman-md-11354510.jpg',
        city: 'Huntington',
        fullName: 'Theodore Lyle Goldman, MD',
        id: 1912968181,
        locationRating: 1.6395032338971256,
        phoneNumber: '(631) 470-8940',
        practiceName: 'Northwell Health Physician Partners Obstetrics and Gynecology at Huntington',
        similarPatients: 1,
        specialties: ['Obstetrics and Gynecology'],
        starRating: 4.84486,
        state: 'NY',
        totalAppointments: 1,
        type: 'Doctor',
        zipCode: '11743',
      },
      {
        addressStreet: '554 Larkfield Road',
        addressSuite: 'Suite 105',
        avatarPath: null,
        city: 'East Northport',
        fullName: 'Chau Hung Lee, DO',
        id: 1407814080,
        locationRating: 2.357502496787503,
        phoneNumber: '(631) 368-3800',
        practiceName: null,
        similarPatients: 1,
        specialties: ['Obstetrics and Gynecology'],
        starRating: null,
        state: 'NY',
        totalAppointments: 1,
        type: 'Doctor',
        zipCode: '11731',
      },
      {
        id: 5942,
        type: 'User',
      },
    ],
  },
  raw_distances: {
    1003046285: {
      distance: 1.2757688625150685,
      location: 9738,
    },
    1033114202: {
      distance: 8.981051432736402,
      location: 653,
    },
    1104080175: {
      distance: 2.966671071936322,
      location: 9784,
    },
    1114961687: {
      distance: 7.393758908596071,
      location: 5118,
    },
    1174703193: {
      distance: 1.2771160379410196,
      location: 5903,
    },
    1184063174: {
      distance: 9.865069087414717,
      location: 2958,
    },
    1184634503: {
      distance: 0.33101673561832806,
      location: 8812,
    },
    1235593211: {
      distance: 2.966671071936322,
      location: 10068,
    },
    1316180292: {
      distance: 0.44596750640635296,
      location: 22636,
    },
    1386640696: {
      distance: 8.981051432736402,
      location: 5412,
    },
    1407814080: {
      distance: 2.357502496787503,
      location: 16063,
    },
    1427070655: {
      distance: 2.961467926659376,
      location: 234,
    },
    1477539260: {
      distance: 8.981051432736402,
      location: 5038,
    },
    1518973593: {
      distance: 8.981051432736402,
      location: 5598,
    },
    1619955960: {
      distance: 3.2922787434289833,
      location: 2348,
    },
    1871564005: {
      distance: 9.865069087414717,
      location: 1263,
    },
    1912968181: {
      distance: 1.6395032338971256,
      location: 3981,
    },
    1982604740: {
      distance: 2.0145205067418943,
      location: 3403,
    },
  },
  raw_visits: {
    1003046285: {
      '90EA3A67-2E34-4ECB-B677-63D51274BCD6': 4,
    },
    1033114202: {
      '16D1CFEF-EAF9-405D-94E9-65DBFBAAC906': 1,
      '511D547C-3794-432F-A815-5E7FA40F31A0': 1,
      '664B2D53-15BE-4922-B419-966A6B8224BB': 1,
      'E1FDDC00-4D4D-4640-98FD-9290FCF4FDA7': 1,
      'F87756A7-9187-4A9D-8A92-F0396155ED9D': 1,
    },
    1104080175: {
      '38B06069-4EDD-48D7-89B2-66DCD241BBB2': 1,
    },
    1114961687: {
      '30721971-F151-4010-A44A-C9F81DBADF24': 1,
    },
    1174703193: {
      '0ACF39DB-9455-489F-AA1E-B69716AACC46': 1,
      '90EA3A67-2E34-4ECB-B677-63D51274BCD6': 2,
    },
    1184063174: {
      'F8B55AA9-9034-432E-B8BB-321A9C42E660': 6,
    },
    1184634503: {
      'A800B901-CD62-4468-9F77-1B97D8BF2889': 1,
    },
    1235593211: {
      '38B06069-4EDD-48D7-89B2-66DCD241BBB2': 2,
    },
    1316180292: {
      '8F18FED3-9F4D-4575-956A-993FEE893361': 2,
    },
    1386640696: {
      '3A13BD68-4995-4446-AFFC-A22CAD44F9FA': 1,
      'AA9A28FE-FE1D-497E-B3FB-289268BEB25C': 1,
    },
    1407814080: {
      '27248AB1-36AB-4D06-A879-1A400EAD2055': 1,
    },
    1427070655: {
      '137648B8-8D57-40C8-90E1-C62CBBC44E2E': 2,
      '3A13BD68-4995-4446-AFFC-A22CAD44F9FA': 4,
      '511D547C-3794-432F-A815-5E7FA40F31A0': 4,
      '6B911155-E6FB-45B7-B793-40F01A532D79': 1,
    },
    1477539260: {
      '5E46CB34-F1E0-4047-A799-6DF161C07ED9': 1,
    },
    1518973593: {
      '137648B8-8D57-40C8-90E1-C62CBBC44E2E': 1,
    },
    1619955960: {
      '137648B8-8D57-40C8-90E1-C62CBBC44E2E': 1,
      '661CA50D-9401-4AD2-9220-FF4A863B09CB': 1,
      'BCED60FB-CB13-4C92-BAE1-CA4E33560C00': 1,
      'BFA57A98-84CA-4FC0-94ED-0E0DE0A2DA11': 6,
      'E1FDDC00-4D4D-4640-98FD-9290FCF4FDA7': 1,
      'F87756A7-9187-4A9D-8A92-F0396155ED9D': 1,
      'FDB26ED0-F2FF-4B3F-8DC9-18BA800EE0F8': 1,
    },
    1871564005: {
      '8A932DD0-27C0-49F8-9257-CD4E7AC97472': 1,
    },
    1912968181: {
      '669096EA-B6D2-4206-BD27-E1D0288E9452': 1,
    },
    1982604740: {
      'BCED60FB-CB13-4C92-BAE1-CA4E33560C00': 15,
    },
  },
}
