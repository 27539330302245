import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  showModal: {
    display: 'block',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
  },
  hideModal: {
    display: 'none',
  },
  shareModalShareThis: {
    pointerEvents: 'none',
  },
  shareThisModalOverlay: {
    position: 'fixed', /* Stay in place */
    zindex: 99999, /* Sit on top */
    left: 0,
    top: 0,
    width: '100%', /* Full width */
    height: '100%', /* Full height */
    overflow: 'auto', /* Enable scroll if needed */
    backgroundColor: 'rgba(0, 0, 0, 0.8)', /* Black w/ opacity */
  },
  shareThisModalHeader: {
    pointerEvents: 'none',
    background: 'black',
    color: 'white',
    fontSize: '60px',
    fontWeight: '100',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeIcon: {
    position: 'absolute',
    top: '10px',
    right: '20px'
  }
}))

// const ShareModal = ({ icon, iconClass, title, subheader }) => {
const ShareModal = ({ show, onClick, name, image, description, url }) => {
  const classes = useStyles()
  useEffect(() => {
    window.addthis.layers.refresh();
    window.addthis.update('share', 'url', url);
    window.addthis.update('share', 'title', name);
    window.addthis.update('share', 'description', description);
    window.addthis.update('share', 'image', image);
  }, [name, description, url, image]);

  return (
    // <Card className={classes.root}>
    //   <CardHeader
    //     avatar={
    //       <Avatar aria-label='people' className={classes[iconClass]}>
    //         {icon}
    //       </Avatar>
    //     }
    //     titleTypographyProps={{ variant: 'h5' }}
    //     title={title}
    //     subheaderTypographyProps={{ variant: 'subtitle1' }}
    //     subheader={subheader}
    //     classes={{
    //       root: classes.cardHeader,
    //     }}
    //   />
    // </Card>
    <div className={show ? classes.showModal : classes.hideModal}><div className={classes.shareThisModalOverlay} onClick={onClick}></div><div className={classes.shareThisModalHeader}>Share <CloseIcon className={classes.closeIcon} /></div><div className={'addthis_inline_share_toolbox_7oo5  ' + classes.shareModalShareThis} data-url={url} data-title={name} data-description={description} data-media={image}></div></div>
  )
}

export default ShareModal