export const actions = {
  SET_INSURANCE_PLAN: 'SET_INSURANCE_PLAN',
  SET_USER_AGE: 'SET_USER_AGE',
  SET_USER_GENDER: 'SET_USER_GENDER',
  SET_USER_LOCATION: 'SET_USER_LOCATION',
  SET_PROVIDER: 'SET_PROVIDER',
  SET_DOC_SEARCH_PROVIDER: 'SET_DOC_SEARCH_PROVIDER',
  SET_USER_GRAPH: 'SET_USER_GRAPH',
  SET_CURRENTLY_SELECTED_PROVIDER: 'SET_CURRENTLY_SELECTED_PROVIDER',
  SET_SPECIALTY: 'SET_SPECIALTY',
  SET_CONDITION: 'SET_CONDITION',
  SET_PROVIDER_SEARCH_RESULTS: 'SET_PROVIDER_SEARCH_RESULTS',
  ADD_PROVIDER: 'ADD_PROVIDER',
  SET_ERROR: 'SET_ERROR',
  SET_STATUS: 'SET_STATUS',
}

const reducer = (state, action) => {
  console.log(`
    🏄🏻‍♀️ — [action: ${action.type}]
    🚚 — [payload: ${JSON.stringify(action.payload)}]
  `)

  switch (action.type) {
    case actions.SET_USER_GENDER:
      return {
        ...state,
        userGender: action.payload,
      }
    case actions.SET_USER_AGE:
      return {
        ...state,
        userAge: action.payload,
      }
    case actions.SET_PROVIDER:
      return {
        ...state,
        provider: action.payload,
      }
    case actions.SET_CURRENTLY_SELECTED_PROVIDER:
      return {
        ...state,
        currentlySelectedProvider: action.payload,
      }
    case actions.SET_SPECIALTY:
      return {
        ...state,
        specialty: action.payload,
      }
    case actions.SET_INSURANCE_PLAN:
      return {
        ...state,
        insurancePlan: action.payload,
      }
    case actions.SET_CONDITION:
      return {
        ...state,
        condition: action.payload,
      }
    case actions.SET_USER_LOCATION:
      return {
        ...state,
        userLocation: action.payload,
      }
    case actions.SET_PROVIDER_SEARCH_RESULTS:
      return {
        ...state,
        providerSearchResults: action.payload,
      }
    case actions.SET_DOC_SEARCH_PROVIDER:
      return {
        ...state,
        searchedProvider: action.payload,
      }
    case actions.ADD_PROVIDER:
      return {
        ...state,
        posts: state.providers.concat(action.payload),
      }
    case actions.SET_USER_GRAPH:
      return {
        ...state,
        userGraphData: action.payload,
      }
    case actions.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case actions.SET_STATUS:
      return {
        ...state,
        status: action.payload,
      }
    default:
      return state
  }
}

export default reducer
