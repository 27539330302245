const themes = [
  {
    id: 'Constellation',
    source: {
      typography: {
        fontFamily: [
          'TheSans',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        h4: {
          fontWeight: 600,
          fontSize: '1.5rem',
        },
        h6: {
          fontWeight: '400',
          fontSize: '0.9rem',
        },
        h5: {
          fontWeight: '400',
          fontSize: '1.1rem',
        },
        subtitle2: {
          fontWeight: 700,
          fontSize: '1.1rem',
        },
        subtitle1: {
          lineHeight: '1.3',
        },
      },
      palette: {
        star: {
          main: '#FFD984',
        },
        green: {
          main: '#4CD964',
        },
        white: {
          main: '#fff',
        },
        lightBlueText: {
          main: '#CBE4FF',
        },
        lightGray: {
          main: '#DBDBDB',
        },
        outlineLightGray: {
          main: '#E3E3E3',
        },
        background: {
          paper: '#fff',
          default: 'rgb(238, 238, 238)',
        },
        primary: {
          main: '#34A8FE',
          contrastText: '#fff',
          hover: '#009adf',
        },
        highlight: {
          main: '#FFC700',
        },
        required: {
          main: '#F77F89',
        },
        darkText: {
          main: '#333333',
        },
        secondary: {
          main: '#fff',
          contrastText: '#000',
        },
        action: {
          // hover: '#ff0033'
        },
      },
    },
  },
]

export default themes
