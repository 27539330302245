import axios from 'axios'

export const BASE_URL = 'https://nwconstellation-releasecandidate.azurewebsites.net'
export const GET_DOCTORS_ENDPOINT = './.netlify/functions/get-doctors'
export const DOC_CONNECTIONS_ENDPOINT = './.netlify/functions/get-doctor-connections'
export const INSURANCE_PLANS_TYPEAHEAD_ENDPOINT = './.netlify/functions/get-insurance-plans'
export const LOCATION_TYPEAHEAD_ENDPOINT = './.netlify/functions/get-user-location'
export const CONDITIONS_TYPEAHEAD_ENDPOINT = './.netlify/functions/get-conditions'
export const PROVIDER_TYPEAHEAD_ENDPOINT = './.netlify/functions/get-provider'
export const SPECIALTY_TYPEAHEAD_ENDPOINT = './.netlify/functions/get-specialty'
export const GET_PROVIDERS_ENDPOINT = './.netlify/functions/get-providers'
export const GET_DOCTOR_PROFILE = './.netlify/functions/get-doctor-profile'
export const GET_DOCTOR_CONSTELLATION = './.netlify/functions/get-doctor-constellation'
export const GET_PROVIDER_DATA = './.netlify/functions/get-provider-data'
export const PRODUCTION_URL = 'http://nwconstellation.azurewebsites.net'

export const STATUS = {
  SETTLED: 0,
  PENDING: 1,
}

export const TOOLTIP_MAP = [
  {
    id: 'patientVisits',
    text: 'This is the number of patients who choose to see this doctor on an annual basis.',
  },
  {
    id: 'connections',
    text: 'Patients who choose to see this doctor tend to share these other physicians in common.',
  },
  {
    id: 'insurance',
    text: 'This field indicates your insurance type. A green check means your insurance is accepted by this doctor.',
  },
  {
    id: 'locations',
    text: 'These are the locations where this doctor practices.',
  },
  {
    id: 'conditions',
    text: 'These are the types of conditions treated by this doctor on an annual basis.',
  },
  {
    id: 'gender',
    text: 'Gender of patients that visit this doctor.',
  },
  {
    id: 'age',
    text: 'Age of patients that visit this doctor.',
  },
  {
    id: 'doctorConnections',
    text: 'Physicians which are in common and share patients.',
  },
]
