import { STATUS } from './constants'
import { apiResponse } from '../config/data'
import { createConstellationData } from './utils'

export const initialState = {
  insurancePlan: null,
  userLocation: null,
  condition: null,
  provider: {},
  userAge: null,
  userGender: null,
  providerSearchResults: createConstellationData(apiResponse),
  status: STATUS.SETTLED,
  patientFilters: ['foo', 'bar'],
  medicalFiltlers: [],
  procedureFilters: [],
  doctorFilters: [],
  userGraphData: null,
  baseProvider: null,
}

// Selectors
export const selectGraphData = (providerState) => providerState.graphData
export const selectProviders = (providerState) => providerState.graphData.nodes.filter((n) => n.type === 'Doctor')
export const selectCounts = (providerState) => providerState.counts
